<template>
  <b-modal
    id="options-modal"
    size="lg"
    title="Add Additional Scope & Upgrades"
    cancel-title="Cancel"
    ok-title="Add Items"
    cancel-variant="outline-secondary"
    :no-close-on-backdrop="true"
    @ok="handleOk"
    @show="resetModal"
    @hidden="resetModal"
    :ok-disabled="isBusy"
    :cancel-disabled="isBusy"
  >
    <b-overlay :show="show" large rounded="lg" spinner-variant="primary">
      <validation-observer ref="accountRules" tag="form">
        <b-row>
          <b-col md="12">
            <h4 style="padding-bottom: 20px">Item Details</h4>
          </b-col>
          <b-col md="12">
            <validation-provider #default="{ errors }" name="item" rules="required">
              <b-form-group>
                <label for="InputHelp">Item Name</label>
                <b-input-group>
                  <b-form-input
                    v-model="item"
                    type="text"
                    placeholder="Ex. Access Control"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="InputHelp">Description for Clients</label>
              <small class="text-muted"> optional</small>
              <b-form-textarea
                id="details"
                v-model="details"
                placeholder="Ex. HID-compatible system, 2x suite entry doors"
                rows="2"
                :state="details.length <= maxChar"
                :class="details.length > maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-right"
                :class="details.length > maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ details.length }}</span> / {{ maxChar }}
              </small>
              <small class="text-muted"
                >Shown to clients if the item is not hidden in turnkey.</small
              >
            </b-form-group>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Internal Note"
              :rules="{
                required: budgetGroup == 'Construction' && selectedRadio == 'adjustment',
              }"
            >
              <b-form-group>
                <label for="InputHelp">Internal Note</label>
                <small
                  class="text-muted"
                  v-if="!(budgetGroup == 'Construction' && selectedRadio == 'adjustment')"
                >
                  optional</small
                >
                <b-form-textarea
                  id="internalNote"
                  v-model="internalNote"
                  placeholder="Internal notes and reason for changes."
                  rows="2"
                  :state="internalNote.length <= maxCharInternal"
                  :class="internalNote.length > maxCharInternal ? 'text-danger' : ''"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="internalNote.length > maxCharInternal ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ internalNote.length }}</span> /
                  {{ maxCharInternal }}
                </small>
                <template v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small
                  ><br />
                </template>
                <small class="text-muted"
                  >This field is required when making an adjustment to base spec.</small
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12" style="padding-top: 0px">
            <hr style="border: 1px solid rgba(31, 41, 47, 0.05)" />
          </b-col>
          <b-col md="12">
            <h4 style="padding-bottom: 20px">Pricing</h4>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="budget group"
              rules="required"
            >
              <b-form-group>
                <label for="InputHelp">Budget Group</label>
                <v-select
                  v-model="budgetGroup"
                  label="title"
                  :options="option"
                  @input="toggleChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12" v-if="budgetGroup != 'Construction'">
            <validation-provider
              #default="{ errors }"
              name="Tender Code"
              :rules="{ required: budgetGroup != 'Construction' }"
            >
              <b-form-group>
                <label for="InputHelp">Tender Code</label>
                <v-select
                  v-model="tenderCode"
                  label="code_name"
                  :options="
                    budgetGroup == 'Construction'
                      ? uniformat.Construction
                      : budgetGroup == 'FF&E'
                      ? uniformat['FF&E']
                      : budgetGroup == 'AV/IT'
                      ? uniformat.AVIT
                      : uniformat['Soft Costs']
                  "
                  item-text="code_name"
                  required
                  :reduce="(tenderCode) => tenderCode.code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6" v-if="budgetGroup !== 'Construction'">
            <validation-provider
              #default="{ errors }"
              name="price"
              :rules="{ required: budgetGroup !== 'Construction' }"
            >
              <b-form-group>
                <label for="InputHelp">Total Price (w/ D&I)</label>
                <b-input-group prepend="$">
                  <b-form-input v-model="price" type="number" placeholder="$0.00" />
                </b-input-group>
                <small class="labelpersqft"
                  >Price/sqft: {{ price / sqft < 0 ? " -" : "" }}
                  {{ "$" + Math.abs(price / sqft).toFixed(2) }}
                </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <template v-else>
            <b-col md="6">
             
                <b-form-group>
                  <b-form-radio-group
                    id="btn-radios-1"
                    v-model="selectedRadio"
                    button-variant="outline-primary"
                    :options="optionsRadio"
                    buttons
                    name="radios-btn-default"
                    style="padding-top: 21px; width: 100%"
                    @change="radioChanged"
                  />
                </b-form-group>
            </b-col>
            <b-col md="6" v-if="selectedRadio == 'new'">
              <validation-provider
                #default="{ errors }"
                name="Tender Code"
                :rules="{ required: budgetGroup == 'Construction' }"
              >
              <b-form-group>
                <label for="InputHelp">Tender Code</label>
                <!-- <v-select
                  v-model="tenderCode"
                  label="code_name"
                  :options="tc"
                  item-text="code_name"
                  :reduce="(tenderCode) => tenderCode.cost_code"
                /> -->
                <v-select
                  v-model="tenderCode"
                  label="code_name"
                  :options="
                    budgetGroup == 'Construction'
                      ? uniformat.Construction
                      : budgetGroup == 'FF&E'
                      ? uniformat['FF&E']
                      : budgetGroup == 'AV/IT'
                      ? uniformat.AVIT
                      : uniformat['Soft Costs']
                  "
                  item-text="code_name"
                  required
                  :reduce="(tenderCode) => tenderCode.code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" v-else>
              <validation-provider
                #default="{ errors }"
                name="Base Spec Construction Item"
                :rules="{ required: isAdjustment == true }"
              >
                <b-form-group>
                  <label for="InputHelp">Base Spec Construction Item</label>
                  <v-select
                    v-model="constructionItem"
                    label="name"
                    :options="constructionItems"
                    item-text="name"
                    :reduce="(constructionItem) => constructionItem.construction_work_id"
                    @input="constructionItemChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" v-if="selectedRadio == 'adjustment'">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <label for="InputHelp">Add or Subtract Quantity</label>
                    <small class="text-muted"> optional</small>
                    <b-input-group>
                      <b-form-input
                        v-model="qty"
                        type="number"
                        placeholder="$0.00"
                        @keyup="constructionPrice()"
                        :readonly="constructionItem == ''"
                        @blur="isEmpty"
                      />
                      <b-input-group-append is-text>
                        {{ isAdjustment ? selectedConstructionItem.unit_type : "" }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="InputHelp">Add or Subtract COGS / Unit</label>
                    <small class="text-muted"> optional</small>
                    <b-input-group prepend="$">
                      <b-form-input
                        v-model="unitCost"
                        type="number"
                        placeholder="$0.00"
                        @keyup="constructionPrice()"
                        :readonly="constructionItem == ''"
                        @blur="isEmpty"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </template>

          <b-col md="6" v-if="selectedRadio !== 'adjustment'">
            <validation-provider #default="{ errors }" name="cost" rules="required">
              <b-form-group>
                <label for="InputHelp">Total COGS</label>
                <b-input-group prepend="$">
                  <b-form-input
                    v-model="cost"
                    type="number"
                    placeholder="$0.00"
                    @keyup="constructionPrice()"
                  />
                </b-input-group>
                <small class="labelpersqft"
                  >COGS/sqft: {{ cost / sqft < 0 ? " -" : "" }}
                  {{
                    "$" +
                    Number(Math.abs(cost / sqft).toFixed(2)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}</small
                ><br />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12" v-if="selectedRadio == 'adjustment'">
            <b-row>
              <b-col md="3">
                <label
                  style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                  >Quantity</label
                >
                <p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  {{
                    constructionItem != ""
                      ? Number(
                          (selectedConstructionItem.qty + qty * 1).toFixed(2)
                        ).toLocaleString(undefined, { minimumFractionDigits: 2 }) +
                        " " +
                        selectedConstructionItem.unit_type
                      : "-"
                  }}
                  <span :class="qty > 0 ? 'increase' : 'decrease'" v-if="qty != 0">
                    <uil-arrow-up v-if="qty > 0" />
                    <uil-arrow-down v-else />{{
                      Number(Math.abs(qty).toFixed(2)).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </span>
                </p>
              </b-col>
              <b-col md="3">
                <label
                  style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                  >COGS / Unit</label
                >
                <p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  {{ selectedConstructionItem.unit_cost + unitCost * 1 < 0 ? " -" : "" }}
                  {{
                    constructionItem != ""
                      ? "$" +
                        Number(
                          (selectedConstructionItem.unit_cost + unitCost * 1).toFixed(2)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "-"
                  }}
                  <span
                    :class="unitCost > 0 ? 'increase' : 'decrease'"
                    v-if="unitCost != 0"
                  >
                    <uil-arrow-up v-if="unitCost > 0" />
                    <uil-arrow-down v-else />{{
                      Number(Math.abs(unitCost)).toFixed(2).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </span>
                </p>
              </b-col>
              <b-col md="3">
                <label
                  style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                  >Total COGS</label
                >
                <p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  {{
                    (selectedConstructionItem.qty + qty * 1) *
                      (selectedConstructionItem.unit_cost + unitCost * 1) <
                    0
                      ? " -"
                      : ""
                  }}
                  {{
                    constructionItem != ""
                      ? "$" +
                        Number(
                          Math.abs(
                            (selectedConstructionItem.qty + qty * 1) *
                              (selectedConstructionItem.unit_cost + unitCost * 1)
                          ).toFixed(2)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "-"
                  }}
                  <span
                    :class="
                      selectedConstructionItem.total_cost <
                      (selectedConstructionItem.qty + qty * 1) *
                        (selectedConstructionItem.unit_cost + unitCost * 1)
                        ? 'increase'
                        : 'decrease'
                    "
                    v-if="qty != 0 || unitCost != 0"
                  >
                    <uil-arrow-up
                      v-if="
                        selectedConstructionItem.total_cost <
                        (selectedConstructionItem.qty + qty * 1) *
                          (selectedConstructionItem.unit_cost + unitCost * 1)
                      "
                    />
                    <uil-arrow-down v-else />{{
                      Number(
                        Math.abs(
                          selectedConstructionItem.total_cost -
                            (selectedConstructionItem.qty + qty * 1) *
                              (selectedConstructionItem.unit_cost + unitCost * 1)
                        ).toFixed(2)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </span>
                </p>
              </b-col>
              <b-col md="3">
                <label
                  style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                  >Total COGS / sqft</label
                >
                <p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  {{
                    ((selectedConstructionItem.qty + qty * 1) *
                      (selectedConstructionItem.unit_cost + unitCost * 1)) /
                      sqft <
                    0
                      ? " -"
                      : ""
                  }}
                  {{
                    constructionItem != ""
                      ? "$" +
                        Number(
                          Math.abs(
                            ((selectedConstructionItem.qty + qty * 1) *
                              (selectedConstructionItem.unit_cost + unitCost * 1)) /
                              sqft
                          ).toFixed(2)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "-"
                  }}
                  <span
                    :class="
                      selectedConstructionItem.cost_per_sqft <
                      ((selectedConstructionItem.qty + qty * 1) *
                        (selectedConstructionItem.unit_cost + unitCost * 1)) /
                        sqft
                        ? 'increase'
                        : 'decrease'
                    "
                    v-if="qty != 0 || unitCost != 0"
                  >
                    <uil-arrow-up
                      v-if="
                        selectedConstructionItem.cost_per_sqft <
                        ((selectedConstructionItem.qty + qty * 1) *
                          (selectedConstructionItem.unit_cost + unitCost * 1)) /
                          sqft
                      "
                    />
                    <uil-arrow-down v-else />{{
                      Number(
                        Math.abs(
                          selectedConstructionItem.cost_per_sqft -
                            parseFloat(
                              ((selectedConstructionItem.qty + qty * 1) *
                                (selectedConstructionItem.unit_cost + unitCost * 1)) /
                                sqft
                            ).toFixed(2)
                        )
                      ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                    }}
                  </span>
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12">
            <b-form-checkbox
              id="checkbox-1"
              v-model="isBaseSpec"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              :disabled="isAdjustment"
            >
              {{
                isAdjustment
                  ? "All Adjustments are Hidden in Turnkey"
                  : "Hide Item in Turnkey"
              }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </validation-observer>
      <template #overlay>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTable,
  BOverlay,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BFormRadioGroup,
} from "bootstrap-vue";
import { required } from "@validations";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import tenderCodes from "@/assets/objects/tender-codes.json";
import { UilArrowUp, UilArrowDown } from "@iconscout/vue-unicons";
import uniformat from "@/assets/objects/uniformat-codes.json";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    BFormInvalidFeedback,
    LottieAnimation,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    BFormRadioGroup,
    UilArrowUp,
    UilArrowDown,
  },
  props: ["quoteId", "sqft", "constructionItems", "base_spec_version"],
  data() {
    return {
      userName: localStorage.getItem("name"),
      show: false,
      item: "",
      details: "",
      internalNote: "",
      price: 0,
      cost: 0,
      unitCost: 0,
      qty: 0,
      budgetGroup: "",
      tenderCode: "",
      constructionItem: "",
      selectedConstructionItem: [],
      required,
      option: ["FF&E", "AV/IT", "Construction"],
      maxChar: 500,
      maxCharInternal: 500,
      isBaseSpec: false,
      tenderCodes: tenderCodes,
      uniformat: uniformat,
      selectedRadio: "new",
      optionsRadio: [
        { text: "New Item", value: "new" },
        { text: "Base Spec Adjustment", value: "adjustment" },
      ],
      isAdjustment: false,
      constructionWorkId: null,
      isBusy: false,
    };
  },
  computed: {
    tc() {
      let tc = [];
      if (Number(this.base_spec_version) < 3) {
        tc = tenderCodes.v1;
      } else {
        tc = tenderCodes.v2.filter((c) => c.cost_code != "0901");
      }
      return tc;
    },
  },
  mounted() {},
  methods: {
    constructionItemChange(value) {
      if (value == null) {
        this.constructionItem = "";
        this.isAdjustment = true;
        this.qty = 0;
        this.unitCost = 0;
        this.selectedConstructionItem = [];
      } else {
        this.selectedConstructionItem = this.constructionItems.find(function (e) {
          return e.construction_work_id == value;
        });
        this.isAdjustment = true;
        this.isBaseSpec = true;
        this.constructionWorkId = this.selectedConstructionItem.construction_work_id;
        this.tenderCode = this.selectedConstructionItem.tender_code;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.validationForm();
    },
    resetModal() {
      this.item = "";
      this.details = "";
      this.price = 0;
      this.cost = 0;
      this.budgetGroup = "";
      this.isBaseSpec = false;
      this.tenderCode = "";
      this.internalNote = "";
      this.isAdjustment = false;
      this.qty = 0;
      this.unitCost = 0;
      this.selectedConstructionItem = [];
      this.isBaseSpec = false;
      this.constructionItem = "";
      this.constructionWorkId = null;
      this.selectedRadio = "new";
    },
    radioChanged(checked) {
      if (this.isAdjustment == true && checked == "new") {
        this.isAdjustment = false;
        this.qty = 0;
        this.unitCost = 0;
        this.price = 0;
        this.cost = 0;
        this.selectedConstructionItem = [];
        this.isBaseSpec = false;
        this.constructionItem = "";
        this.constructionWorkId = null;
        this.tenderCode = null;
      } else if (checked == "adjustment") {
        this.isAdjustment = true;
        this.isBaseSpec = true;
      }
    },
    constructionPrice() {
      if (this.budgetGroup == "Construction") {
        this.price = this.cost;
      }
    },
    isEmpty() {
      if (this.unitCost == "") {
        this.unitCost = 0;
      }
      if (this.qty == "") {
        this.qty = 0;
      }
    },
    toggleChanged() {
      if (this.isAdjustment == true && this.budgetGroup != "Construction") {
        this.isAdjustment = false;
        this.qty = 0;
        this.unitCost = 0;
        this.selectedConstructionItem = [];
        this.isBaseSpec = false;
        this.constructionItem = "";
        this.selectedRadio = "new";
        this.constructionWorkId = null;
      }
      if (this.budgetGroup == "Construction" && this.selectedRadio == "new") {
        this.price = this.cost = 0;
      } else if (this.budgetGroup == "Construction") {
        this.price = this.cost;
      } else {
        this.tenderCode = null;
        this.price = this.cost = 0;
      }
      this.tenderCode = null;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (this.isAdjustment == true) {
            this.cost =
              (this.selectedConstructionItem.qty + this.qty * 1) *
                (this.selectedConstructionItem.unit_cost + this.unitCost * 1) -
              this.selectedConstructionItem.total_cost;
            this.price = this.cost;
          }
          if (success) {
            this.isBusy = true;
            resolve(true);
            this.show = true;
            this.$http
              .post(`/quote/${this.quoteId}/options`, {
                item: this.item,
                details: this.details,
                sqft: this.sqft,
                quoteId: this.quoteId,
                total_price: this.price,
                total_cost: this.cost,
                cost_per_sqft: this.cost / this.sqft,
                price_per_sqft: this.price / this.sqft,
                budget_group: this.budgetGroup,
                is_base_spec: this.isBaseSpec,
                // tender_code: this.tenderCode,
                quantity: this.qty,
                unit_cost: this.unitCost,
                unit_price: 0,
                is_adjustment: this.isAdjustment,
                internal_note: this.internalNote,
                construction_work_id: this.constructionWorkId,
                uniformat: this.tenderCode,
              })
              .then((response) => {
                this.$emit("updatedOptions", response.data);
                this.isBusy = false;
                this.show = false;
                this.$bvModal.hide("options-modal");
              })
              .catch((error) => {
                this.show = false;
                this.isBusy = false;
                this.showToast("danger", error.response.data.message);
              });
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.increase {
  color:  rgba(59, 171, 255, 1) ;
  font-size: 12px;
}

.decrease {
  color: #ea5455;
  font-size: 12px;
}
</style>

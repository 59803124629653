<template>
  <section class="invoice-preview-wrapper">
    <div v-show="show" id="lottie">
      <div class="loading-logo">
        <img style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>

    <b-col v-if="quote.id" class="notprintable">
      <b-row>
        <b-col
          cols="7"
          style="font-weight: 600; font-size: 11.9px; line-height: 12px"
        >
          <quote-info-header :quote="quote" />
          <!-- <h2
            v-if="quote.id"
            class="notprintable"
            style="font-weight: 700 !important; font-size: 28px!important; line-height: 34px;!important"
          >
            {{ quote.opportunity.address + ", " + quote.opportunity.geography }}
          </h2>
          <uil-suitcase class="logo" size="15px" />
          {{ quote.opportunity.account_name }}
          <span style="padding-left: 20px">
            <uil-tape class="logo" size="15px" />
            {{ quote.opportunity.rentable_sqft.toLocaleString('en', {useGrouping:true}) + " sqft" }}
          </span>
          <span style="padding-left: 20px">
            <uil-bill class="logo" size="15px" />
            {{ quote.tfr_name }}
          </span>
          <span style="padding-left: 20px; font-weight: 400; color: #b9b9c3">
            {{ "DC Version " + quote.base_spec_version }}
          </span>
          <p class="pt-1">
            <b-badge pill :class="badgeClass(quote.tfr.tfr_status)">
              {{ quote.tfr.tfr_status }}
            </b-badge>
          </p> -->
        </b-col>
        <b-col align-self="end" style="text-align: end; padding-bottom: 20px">
          <b-button
            role="button"
            style="margin-right: 10px"
            target="_blank"
            variant="outline-secondary"
            @click="downloadCadFile"
          >
            Download CAD Data
            <feather-icon icon="DownloadIcon" />
          </b-button>
          <!-- <b-button
            :href="`https://clearspace.lightning.force.com/lightning/r/Test_Fit_Request__c/${quote.tfr.sf_tfr_id}/view`"
            role="button"
            style="margin-right: 10px"
            target="_blank"
            variant="outline-secondary"
            :disabled="!quote.tfr.sf_tfr_id"
          >
            Open TFR
            <feather-icon icon="ExternalLinkIcon" />
          </b-button> -->
          <b-button
            style="margin-right: 10px"
            variant="outline-danger"
            @click="showModal"
            v-if="quoteHasChanged || hasComment"
          >
            Discard Changes
          </b-button>
          <b-button
            style="margin-right: 10px"
            variant="outline-secondary"
            @click="exitEditMode"
            v-else
          >
            Exit Edit Mode
          </b-button>
          <b-button
            variant="primary"
            @click="updateQuote"
            :disabled="
              quote.tfr.tfr_status == 'Quote Completed' ||
              quote.opportunity.ccdc_signed == true ||
              quote.tfr.tfr_status == 'Archived'
            "
          >
            Save Quote
          </b-button>
        </b-col>
      </b-row>
    </b-col>
    <b-row v-if="quote.id" class="invoice-preview edit-page">
      <!-- Left Col: Card -->
      <!-- <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <comment-section :comments='comments' :quoteId='quoteId' />
      </b-col> -->
      <b-col cols="12" md="12" xl="12">
        <b-tabs
          v-model="tabIndex"
          pills
          vertical
          nav-wrapper-class="col-3"
          content-class="col-9"
        >
          <template v-if="!isBusy">
            <div
              v-if="
                (quote.tfr.tfr_status == 'Quote Completed' ||
                  quote.opportunity.ccdc_signed == true) &&
                quote.tfr.tfr_status != 'Archived'
              "
              class="archived-warning"
            >
              <span
                >Quote is Complete or Marked as CCDC Signed and can no longer be
                edited.</span
              >
            </div>
            <div
              v-if="quote.tfr.tfr_status == 'Archived'"
              class="archived-warning-archived"
            >
              <span>Estimate is archived and cannot be edited.</span>
            </div>
          </template>
          <b-tab active>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-bill class="logo" size="15px" />
                Summary
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <!-- Col: Right (Invoice Container) -->
            <template v-if="!isBusy">
              <div
                v-if="showFFEAlert == true || showAVITAlert == true"
                class="alert-warning"
              >
                <span>
                  <UilExclamationTriangle fill="#FF9F43" size="15px" />
                  Options are still being included in a disabled budget
                  group.</span
                >
              </div>
            </template>

            <b-card class="invoice-preview-card" no-body>
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                    </div>
                    <p class="card-text mb-25">Clearspace Offices Inc.</p>
                    <p class="card-text mb-25 base-paragraph">
                      901-20 Victoria Street
                      <!-- {{
                        quote.geography != "Montréal"
                          ? "901-20 Victoria Street"
                          : montrealAddress
                      }} -->
                    </p>
                    <p class="card-text mb-0 base-paragraph">
                      Toronto, ON, Canada M5C 2N8
                      <!-- {{
                        quote.geography != "Montréal"
                          ? "Toronto, ON, Canada M5C 2N8"
                          : "Montréal, QC, Canada H3B 2N2"
                      }} -->
                    </p>
                    <div
                      v-if="quote.geography == 'Montréal'"
                      class="mt-md-0 mt-2"
                      v-html="$t('changeOrder.changeOrderLicenseCF', 'en')"
                    ></div>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="mt-md-0 mt-2">
                    <h4 class="invoice-numbercs mb-2" style="text-align: end">
                      Quote #{{ quote.tfr.tfr }}
                    </h4>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title header-label">Issue Date:</p>
                      <p class="invoice-date">
                        <b-input-group class="mb-1">
                          <b-form-input
                            id="example-input"
                            v-model="quote.issue_date"
                            autocomplete="off"
                            placeholder="YYYY-MM-DD"
                            show-decade-nav
                            type="text"
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="quote.issue_date"
                              aria-controls="example-input"
                              button-only
                              button-variant="outline-secondary"
                              locale="en-US"
                              right
                              show-decade-nav
                              size="sm"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </p>
                    </div>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title header-label">
                        Expiry Date:
                      </p>
                      <p class="invoice-date">
                        {{
                          new Date(quote.expiration_date).toLocaleDateString(
                            "en-CA"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer
      <hr class="invoice-spacing">
      -->

              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col class="p-0" cols="9">
                    <h6 class="header-label">Address</h6>
                    <h2
                      class="card-text mb-25"
                      style="
                        font-weight: 700 !important;
                        font-size: 21px !important;
                        line-height: 26px !important;
                        color: #636363 !important;
                      "
                    >
                      {{ quote.address }}
                    </h2>
                    <h2
                      class="card-text mb-25"
                      style="
                        font-weight: 700 !important;
                        font-size: 21px !important;
                        line-height: 26px !important;
                        color: #636363 !important;
                      "
                    >
                      {{ quote.geography }}
                      <!-- {{quote.project.city + ', ' + quote.project.state + ', Canada ' + quote.project.postcode}} -->
                    </h2>
                    <h6 class="mt-2 header-label">Size</h6>
                    <p class="card-text mb-2">
                      {{
                        Number(quote.sqft).toLocaleString("en", {
                          minimumFractionDigits: 0,
                        }) + " sqft"
                      }}
                    </p>
                  </b-col>
                  <!-- <b-col
                      class="p-0"
                      cols="5"
                  >
                    <h6 class="mb-1 header-label">
                      Bill To
                    </h6>
                    <p class="card-text mb-25">
                      {{ quote.opportunity.account_name }}
                    </p>
                    <p class="card-text mb-25">
                      {{ quote.opportunity.contact_name }}
                    </p>
                    <p class="card-text mb-25">
                      {{ quote.opportunity.contact_phone }}
                    </p>
                    <p class="card-text mb-0">
                      {{ quote.opportunity.contact_email }}
                    </p>
                  </b-col> -->

                  <!-- Col: Payment Details -->
                  <b-col
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                    cols="3"
                  >
                    <div class="width-100p">
                      <h6 class="mb-2 header-label">Quote Summary</h6>
                      <p class="header-label-grey">$ per sqft</p>
                      <table class="width-100p">
                        <tbody>
                          <tr>
                            <td class="pr-1 pb-1" colspan="4">Turnkey Suite</td>
                            <td class="header-subtotal pr-1 pb-1" colspan="1">
                              {{
                                "$" +
                                Number(
                                  quote.construction_cost_per_sqft +
                                    quote.site_conditions_construction_price_per_sqft +
                                    quote.options_construction_price_per_sqft +
                                    quote.construction_buffer * 1 +
                                    Number(
                                      (!quote.ffe_toggle
                                        ? 0
                                        : quote.ffe_price_per_sqft) +
                                        quote.options_ffe_price_per_sqft +
                                        Number(
                                          (!quote.avit_toggle
                                            ? 0
                                            : quote.avit_price_per_sqft) +
                                            quote.options_avit_price_per_sqft
                                        ) +
                                        Number(
                                          quote.construction_pm_buffer * 1 +
                                            quote.design_ame_buffer * 1 +
                                            Number(
                                              quote.engineering_fee_price
                                            ) *
                                              1
                                        ) +
                                        Number(quote.permit_fees / quote.sqft)
                                    )
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 pb-1" colspan="4">
                              Site Conditions
                            </td>
                            <td class="header-subtotal pr-1 pb-1" colspan="1">
                              {{
                              [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_price_per_sqft))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                              }}
                            </td>
                          </tr>
                          <tr style="border-bottom: 1px solid #d8d6de">
                            <td class="pr-1 pb-1" colspan="4">
                              Additional Scope & Upgrades
                            </td>
                            <td class="header-subtotal pr-1 pb-1" colspan="1">
                              {{
                              [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_price_per_sqft))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1 pb-2 pt-2 project-sqft" colspan="4">
                              Project $/sqft
                            </td>
                            <td
                              class="project-sqft-total pr-1 pb-1 pt-2"
                              colspan="1"
                            >
                              {{
                                "$" +
                                Number(
                                  quote.construction_cost_per_sqft +
                                    quote.site_conditions_construction_price_per_sqft +
                                    quote.options_construction_price_per_sqft +
                                    quote.construction_buffer * 1 +
                                    Number(
                                      (!quote.ffe_toggle
                                        ? 0
                                        : quote.ffe_price_per_sqft) +
                                        quote.options_ffe_price_per_sqft +
                                        Number(
                                          (!quote.avit_toggle
                                            ? 0
                                            : quote.avit_price_per_sqft) +
                                            quote.options_avit_price_per_sqft
                                        ) +
                                        Number(
                                          quote.construction_pm_buffer * 1 +
                                            quote.design_ame_buffer * 1 +
                                            Number(
                                              quote.engineering_fee_price
                                            ) *
                                              1
                                        ) +
                                        Number(quote.permit_fees / quote.sqft)
                                    ) +
                                    Number(
                                      quote.site_conditions_price_per_sqft * 1
                                    ) +
                                    Number(quote.options_price_per_sqft * 1)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="project-sqft pr-1 pb-1" colspan="4">
                              Project Total
                            </td>
                            <td class="header-subtotal pr-1 pb-1" colspan="1">
                              {{
                                "$" +
                                Number(
                                  Number(
                                    quote.construction_buffer * 1 * quote.sqft +
                                      siteConditionsConstructionSubtotal +
                                      optionsConstructionSubtotal +
                                      quote.construction_calc
                                  ) +
                                    Number(
                                      (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                                        optionsFfeSubtotal
                                    ) +
                                    Number(
                                      (!quote.avit_toggle
                                        ? 0
                                        : quote.avit_calc) + optionsAvItSubtotal
                                    ) +
                                    Number(
                                      (quote.construction_pm_buffer * 1 +
                                        quote.design_ame_buffer * 1 +
                                        Number(quote.engineering_fee_price) *
                                          1) *
                                        quote.sqft
                                    ) +
                                    Number(quote.permit_fees) +
                                    Number(quote.options_total_price) +
                                    Number(quote.site_conditions_total_price)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Invoice Description: Table -->
              <b-table-simple
                caption-top
                responsive
                style="padding-right: 28px; padding-left: 28px"
              >
                <caption class="caption-table">
                  Turnkey Suite
                </caption>
                <b-thead>
                  <b-tr>
                    <b-th
                      class="table-background-color"
                      colspan="2"
                      style="text-align: left"
                    >
                      SERVICES
                    </b-th>
                    <b-th
                      class="table-background-color"
                      colspan="3"
                      style="text-align: left"
                    >
                      PRICE/SQFT
                    </b-th>
                    <b-th
                      class="table-background-color cost-header"
                      colspan="3"
                      style="text-align: left"
                    >
                      COGS / SQFT
                    </b-th>
                    <b-th
                      class="table-background-color"
                      colspan="2"
                      style="text-align: left"
                    >
                      SUBTOTAL
                    </b-th>
                    <b-th
                      class="table-background-color cost-header"
                      colspan="1"
                      style="text-align: left"
                    >
                      PROFIT/SQFT
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td class="row-label" colspan="2">
                      Hard Construction Costs
                    </b-td>
                    <b-td class="row-label" colspan="3"
                      >${{
                        (
                          quote.construction_cost_per_sqft +
                          quote.site_conditions_construction_price_per_sqft +
                          quote.options_construction_price_per_sqft +
                          quote.construction_buffer * 1
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label cost-line" colspan="3"
                      >{{
                        "$" +
                        Number(
                          quote.construction_cost_per_sqft +
                            quote.options_construction_cost_per_sqft +
                            quote.site_conditions_construction_cost_per_sqft
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td colspan="2"
                      >{{
                        "$" +
                        Number(
                          quote.construction_buffer * 1 * quote.sqft +
                            siteConditionsConstructionSubtotal +
                            optionsConstructionSubtotal +
                            quote.construction_calc
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td colspan="1">
                      <b-form-input
                        v-model="quote.construction_buffer"
                        step="0.01"
                        type="number"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr
                    class="remove-border hidden-flex-line"
                    v-if="checkHiddenConstruction"
                  >
                    <b-td class="child-line row-label" colspan="2">
                      Additional Scope & Upgrades/Site Conditions included in
                      Turnkey
                    </b-td>
                    <b-td class="row-label child-line" colspan="3"
                      >{{
                        "$" +
                        Number(
                          quote.site_conditions_construction_price_per_sqft +
                            quote.options_construction_price_per_sqft
                        )
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="3"
                      >{{
                        "$" +
                        Number(
                          quote.options_construction_cost_per_sqft +
                            quote.site_conditions_construction_cost_per_sqft
                        )
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="2"
                      >{{
                        "$" +
                        Number(
                          siteConditionsConstructionSubtotal +
                            optionsConstructionSubtotal
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="1"
                      >{{
                        "$" +
                        (
                          Number(
                            quote.site_conditions_construction_price_per_sqft +
                              quote.options_construction_price_per_sqft
                          ) -
                          Number(
                            quote.options_construction_cost_per_sqft +
                              quote.site_conditions_construction_cost_per_sqft
                          )
                        )
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                  <b-tr :class="!checkHiddenFFE ? ' border-section ' : ''">
                    <b-td
                      :class="
                        !quote.ffe_toggle && !checkHiddenFFE
                          ? 'row-label border-child cost-line'
                          : 'row-label border-child'
                      "
                      colspan="2"
                      >Furniture & Fixtures
                    </b-td>
                    <b-td
                      :class="
                        !quote.ffe_toggle && !checkHiddenFFE
                          ? 'row-label border-child cost-line'
                          : 'row-label border-child'
                      "
                      colspan="3"
                      >${{
                        Number(
                          (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                            quote.options_ffe_price_per_sqft
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label border-child cost-line" colspan="3"
                      >{{
                        "$" +
                        Number(
                          (!quote.ffe_toggle
                            ? 0
                            : quote.ffe_cost_per_sqft +
                              quote.ffe_installation_price_per_sqft) +
                            quote.options_ffe_cost_per_sqft
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td
                      :class="
                        !quote.ffe_toggle && !checkHiddenFFE
                          ? 'border-child cost-line'
                          : 'border-child'
                      "
                      colspan="2"
                      >{{
                        "$" +
                        Number(
                          (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                            optionsFfeSubtotal
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td
                      :class="
                        (!quote.ffe_toggle && !checkHiddenFFE
                          ? 'border-child cost-line'
                          : 'border-child') + ' cost-line'
                      "
                      colspan="1"
                      >{{
                        "$" +
                        Number(
                          (!quote.ffe_toggle ? 0 : quote.ffe_buffer) +
                            Number(quote.options_ffe_price_per_sqft) -
                            Number(quote.options_ffe_cost_per_sqft)
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                  <b-tr class="hidden-flex-line" v-if="checkHiddenFFE">
                    <b-td class="child-line row-label" colspan="2">
                      Additional Scope & Upgrades included in Turnkey
                    </b-td>
                    <b-td class="row-label child-line" colspan="3"
                      >{{
                        "$" +
                        Number(quote.options_ffe_price_per_sqft)
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="3"
                      >{{
                        "$" +
                        Number(quote.options_ffe_cost_per_sqft)
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="2"
                      >{{
                        "$" +
                        Number(optionsFfeSubtotal).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="1"
                      >${{
                        (
                          Number(quote.options_ffe_price_per_sqft) -
                          Number(quote.options_ffe_cost_per_sqft)
                        )
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                  <b-tr :class="!checkHiddenAVIT ? ' border-section ' : ''">
                    <b-td
                      :class="
                        !quote.avit_toggle && !checkHiddenAVIT
                          ? 'row-label border-child cost-line'
                          : 'row-label border-child'
                      "
                      colspan="2"
                      >AV / IT
                    </b-td>
                    <b-td
                      :class="
                        !quote.avit_toggle && !checkHiddenAVIT
                          ? 'row-label border-child cost-line'
                          : 'row-label border-child'
                      "
                      colspan="3"
                    >
                      {{
                        "$" +
                        Number(
                          (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                            quote.options_avit_price_per_sqft
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label border-child cost-line" colspan="3"
                      >{{
                        "$" +
                        Number(
                          (!quote.avit_toggle
                            ? 0
                            : quote.avit_cost_per_sqft +
                              quote.avit_installation_price_per_sqft) +
                            quote.options_avit_cost_per_sqft
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td
                      :class="
                        !quote.avit_toggle && !checkHiddenAVIT
                          ? 'border-child cost-line'
                          : 'border-child'
                      "
                      colspan="2"
                      >{{
                        "$" +
                        Number(
                          (!quote.avit_toggle ? 0 : quote.avit_calc) +
                            optionsAvItSubtotal
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td
                      :class="
                        (!quote.avit_toggle && !checkHiddenAVIT
                          ? 'border-child cost-line'
                          : 'border-child') + ' cost-line'
                      "
                      colspan="1"
                      >{{
                        "$" +
                        Number(
                          (!quote.avit_toggle ? 0 : quote.avit_buffer) +
                            (quote.options_avit_price_per_sqft -
                              quote.options_avit_cost_per_sqft)
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                  <b-tr class="hidden-flex-line" v-if="checkHiddenAVIT">
                    <b-td class="child-line row-label" colspan="2">
                      Additional Scope & Upgrades included in Turnkey
                    </b-td>
                    <b-td class="row-label child-line" colspan="3"
                      >{{
                        "$" +
                        Number(quote.options_avit_price_per_sqft)
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="3"
                      >{{
                        "$" +
                        Number(quote.options_avit_cost_per_sqft)
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="2"
                      >{{
                        "$" +
                        Number(optionsAvItSubtotal).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="1"
                      >{{
                        "$" +
                        Number(
                          quote.options_avit_price_per_sqft -
                            quote.options_avit_cost_per_sqft
                        )
                          .toFixed(2)
                          .toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="row-label border-child" colspan="2">
                      Clearspace Design & Management Fee
                    </b-td>
                    <b-td class="row-label border-child" colspan="3"
                      >{{
                        "$" +
                        Number(
                          quote.construction_pm_buffer * 1 +
                            quote.design_ame_buffer * 1 +
                            Number(quote.engineering_fee_price) * 1
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label border-child cost-line" colspan="3">
                    </b-td>
                    <b-td class="border-child" colspan="2"
                      >{{
                        "$" +
                        Number(
                          (quote.construction_pm_buffer * 1 +
                            quote.design_ame_buffer * 1 +
                            Number(quote.engineering_fee_price) * 1) *
                            quote.sqft
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="border-child" colspan="1" />
                  </b-tr>
                  <b-tr>
                    <b-td class="child-label border-child" colspan="2">
                      Design + Architectural
                    </b-td>
                    <b-td class="row-label border-child child-line" colspan="3"
                      >{{
                        "$" +
                        Number(quote.design_ame_buffer * 1).toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                          }
                        )
                      }}
                    </b-td>
                    <b-td class="row-label border-child cost-line" colspan="3">
                    </b-td>
                    <b-td class="border-child" colspan="2" />
                    <b-td class="border-child" colspan="1">
                      <b-form-input
                        v-model="quote.design_ame_buffer"
                        type="number"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="child-label border-child" colspan="2">
                      Construction + Project Management
                    </b-td>
                    <b-td class="row-label child-line border-child" colspan="3"
                      >{{
                        "$" +
                        Number(quote.construction_pm_buffer * 1).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2 }
                        )
                      }}
                    </b-td>
                    <b-td class="row-label border-child cost-line" colspan="3">
                    </b-td>
                    <b-td
                      class="row-label child-line border-child"
                      colspan="2"
                    />
                    <b-td colspan="1" class="border-child">
                      <b-form-input
                        v-model="quote.construction_pm_buffer"
                        type="number"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="child-label" colspan="2">
                      Engineering Fee
                    </b-td>
                    <b-td class="row-label child-line" colspan="3"
                      >{{
                        "$" +
                        Number(
                          Number(Number(quote.engineering_fee_price)) * 1
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line cost-line" colspan="3"
                      >{{
                        "$" +
                        Number(
                          Number(quote.engineering_fee_cost) * 1
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label child-line" colspan="2" />
                    <b-td class="row-label child-line cost-line" colspan="1"
                      >{{

                      [((Number(Number(quote.engineering_fee_price)) * 1)-(Number(quote.engineering_fee_cost) * 1) &lt; 0) ? '-' : ''] +'$' + Math.abs(Number(((Number(Number(quote.engineering_fee_price)) * 1)-(Number(quote.engineering_fee_cost) * 1))))
                        .toLocaleString('en', { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="row-label" colspan="2"> Permit Fees </b-td>
                    <b-td class="row-label" colspan="3"
                      >{{
                        "$" +
                        Number(quote.permit_fees / quote.sqft).toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                          }
                        )
                      }}
                    </b-td>
                    <b-td class="row-label cost-line" colspan="3"
                      >{{
                        "$" +
                        Number(quote.permit_fees / quote.sqft).toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                          }
                        )
                      }}
                    </b-td>
                    <b-td colspan="2"
                      >{{
                        "$" +
                        Number(quote.permit_fees).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })
                      }}
                    </b-td>
                    <b-td colspan="1" />
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr class="border-footer">
                    <b-td class="row-label" colspan="2">
                      Turnkey Suite Subtotal
                    </b-td>
                    <b-td class="row-label turnkey-subtotal-primary" colspan="3"
                      >${{
                        Number(
                          quote.construction_cost_per_sqft +
                            quote.site_conditions_construction_price_per_sqft +
                            quote.options_construction_price_per_sqft +
                            quote.construction_buffer * 1 +
                            Number(
                              (!quote.ffe_toggle
                                ? 0
                                : quote.ffe_price_per_sqft) +
                                quote.options_ffe_price_per_sqft +
                                Number(
                                  (!quote.avit_toggle
                                    ? 0
                                    : quote.avit_price_per_sqft) +
                                    quote.options_avit_price_per_sqft
                                ) +
                                Number(
                                  quote.construction_pm_buffer * 1 +
                                    quote.design_ame_buffer * 1 +
                                    Number(quote.engineering_fee_price) * 1
                                ) +
                                Number(quote.permit_fees / quote.sqft)
                            )
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td
                      class="row-label turnkey-subtotal-secondary"
                      colspan="3"
                      >${{
                        Number(
                          Number(
                            quote.construction_cost_per_sqft +
                              quote.options_construction_cost_per_sqft +
                              quote.site_conditions_construction_cost_per_sqft
                          ) +
                            Number(
                              (!quote.ffe_toggle
                                ? 0
                                : quote.ffe_cost_per_sqft +
                                  quote.ffe_installation_price_per_sqft) +
                                quote.options_ffe_cost_per_sqft
                            ) +
                            Number(
                              (!quote.avit_toggle
                                ? 0
                                : quote.avit_cost_per_sqft +
                                  quote.avit_installation_price_per_sqft) +
                                quote.options_avit_cost_per_sqft
                            ) +
                            Number(Number(quote.engineering_fee_cost) * 1) +
                            Number(quote.permit_fees / quote.sqft)
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td class="row-label turnkey-subtotal-primary" colspan="2"
                      >${{
                        Number(
                          Number(
                            quote.construction_pm_buffer * 1 +
                              quote.design_ame_buffer * 1 +
                              Number(quote.engineering_fee_price) * 1
                          ) *
                            quote.sqft +
                            Number(quote.permit_fees) +
                            Number(
                              (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                                optionsFfeSubtotal
                            ) +
                            Number(
                              (!quote.avit_toggle ? 0 : quote.avit_calc) +
                                optionsAvItSubtotal
                            ) +
                            Number(
                              quote.construction_buffer * 1 * quote.sqft +
                                siteConditionsConstructionSubtotal +
                                optionsConstructionSubtotal +
                                quote.construction_calc
                            )
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                    <b-td
                      class="row-label turnkey-subtotal-secondary"
                      colspan="3"
                      >${{
                        Number(
                          +Number(quote.construction_buffer) +
                            Number(
                              quote.site_conditions_construction_price_per_sqft +
                                quote.options_construction_price_per_sqft
                            ) -
                            Number(
                              quote.options_construction_cost_per_sqft +
                                quote.site_conditions_construction_cost_per_sqft
                            ) +
                            Number(!quote.ffe_toggle ? 0 : quote.ffe_buffer) +
                            Number(quote.options_ffe_price_per_sqft) -
                            Number(quote.options_ffe_cost_per_sqft) +
                            Number(!quote.avit_toggle ? 0 : quote.avit_buffer) +
                            Number(quote.options_avit_price_per_sqft) -
                            Number(quote.options_avit_cost_per_sqft) +
                            Number(quote.design_ame_buffer) +
                            Number(quote.construction_pm_buffer) +
                            Number(
                              Number(quote.engineering_fee_price) * 1 -
                                quote.engineering_fee_cost * 1
                            )
                        ).toLocaleString("en", { minimumFractionDigits: 2 })
                      }}
                    </b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
              <!-- Spacer -->
              <div
                v-if="
                  Number(Math.abs(quote.site_conditions_price_per_sqft)) <= 0 &&
                  Number(Math.abs(quote.options_price_per_sqft)) <= 0
                "
              >
                <hr class="invoice-spacing" style="border: 2px solid #82868b" />
                <b-table-simple
                  fixed
                  style="
                    padding-right: 28px;
                    padding-left: 28px;
                    overflow-y: hidden;
                  "
                >
                  <b-tfoot>
                    <b-tr>
                      <b-td
                        class="row-label project-total-label"
                        colspan="4"
                        style="text-align: left; border-top: none"
                      >
                        Project Total
                      </b-td>
                      <b-td
                        class="row-label project-total-number"
                        colspan="2"
                        style="border-top: none"
                      >
                        ${{
                          Number(
                            quote.construction_cost_per_sqft +
                              quote.site_conditions_construction_price_per_sqft +
                              quote.options_construction_price_per_sqft +
                              quote.construction_buffer * 1 +
                              Number(
                                (!quote.ffe_toggle
                                  ? 0
                                  : quote.ffe_price_per_sqft) +
                                  quote.options_ffe_price_per_sqft +
                                  Number(
                                    (!quote.avit_toggle
                                      ? 0
                                      : quote.avit_price_per_sqft) +
                                      quote.options_avit_price_per_sqft
                                  ) +
                                  Number(
                                    quote.construction_pm_buffer * 1 +
                                      quote.design_ame_buffer * 1 +
                                      Number(quote.engineering_fee_price) * 1
                                  ) +
                                  Number(quote.permit_fees / quote.sqft)
                              ) +
                              Number(quote.site_conditions_price_per_sqft * 1) +
                              Number(quote.options_price_per_sqft * 1)
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        class="row-label project-total-number cost-line"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          "$" +
                          Number(
                            Number(
                              quote.construction_cost_per_sqft +
                                quote.options_construction_cost_per_sqft +
                                quote.site_conditions_construction_cost_per_sqft
                            ) +
                              Number(
                                (!quote.ffe_toggle
                                  ? 0
                                  : quote.ffe_cost_per_sqft +
                                    quote.ffe_installation_price_per_sqft) +
                                  quote.options_ffe_cost_per_sqft
                              ) +
                              Number(
                                (!quote.avit_toggle
                                  ? 0
                                  : quote.avit_cost_per_sqft +
                                    quote.avit_installation_price_per_sqft) +
                                  quote.options_avit_cost_per_sqft
                              ) +
                              Number(Number(quote.engineering_fee_cost) * 1) +
                              Number(quote.permit_fees / quote.sqft) +
                              Number(quote.site_conditions_cost_per_sqft) +
                              Number(quote.options_cost_per_sqft)
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        class="row-label project-total-number"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          "$" +
                          Number(
                            Number(
                              quote.construction_buffer * 1 * quote.sqft +
                                siteConditionsConstructionSubtotal +
                                optionsConstructionSubtotal +
                                quote.construction_calc
                            ) +
                              Number(
                                (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                                  optionsFfeSubtotal
                              ) +
                              Number(
                                (!quote.avit_toggle ? 0 : quote.avit_calc) +
                                  optionsAvItSubtotal
                              ) +
                              Number(
                                (quote.construction_pm_buffer * 1 +
                                  quote.design_ame_buffer * 1 +
                                  Number(quote.engineering_fee_price) * 1) *
                                  quote.sqft
                              ) +
                              Number(quote.permit_fees) +
                              Number(quote.options_total_price) +
                              Number(quote.site_conditions_total_price)
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        class="project-total-number cost-line"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          "$" +
                          Number(
                            quote.construction_buffer * 1 +
                              (Number(
                                quote.site_conditions_construction_price_per_sqft +
                                  quote.options_construction_price_per_sqft
                              ) -
                                Number(
                                  quote.options_construction_cost_per_sqft +
                                    quote.site_conditions_construction_cost_per_sqft
                                )) +
                              Number(!quote.ffe_toggle ? 0 : quote.ffe_buffer) +
                              Number(quote.options_ffe_price_per_sqft) -
                              Number(quote.options_ffe_cost_per_sqft) +
                              Number(
                                !quote.avit_toggle ? 0 : quote.avit_buffer
                              ) +
                              Number(quote.options_avit_price_per_sqft) -
                              Number(quote.options_avit_cost_per_sqft) +
                              Number(
                                quote.options_price_per_sqft -
                                  quote.options_cost_per_sqft
                              ) +
                              Number(
                                quote.site_conditions_price_per_sqft -
                                  quote.site_conditions_cost_per_sqft
                              ) +
                              quote.design_ame_buffer +
                              quote.construction_pm_buffer +
                              Number(
                                Number(Number(quote.engineering_fee_price)) *
                                  1 -
                                  Number(quote.engineering_fee_cost) * 1
                              )
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </div>

              <!-- Note -->
              <!--              <b-card-body class="invoice-padding pt-0" style="visibility: hidden">-->
              <!--                <span class="disclaimer">This pricing does not contemplate liquidated damages. If liquidated damages are required, a premium will apply.<br>-->
              <!--                  Quote does NOT include <span v-if="quote.opportunity.geography == 'Toronto'">HST.</span><span-->
              <!--                      v-else-->
              <!--                  >include GST + QST.</span> <br>-->
              <!--                  Quote does NOT include any landlord required reviews and backcharges (incl. after hour fees, commissioning, security, engineer reviews, etc.).<br>-->
              <!--                  Changes to the scope of work listed above will be priced separately and agreed to in writing before additional work is performed. </span>-->
              <!--              </b-card-body>-->
            </b-card>
            <b-card
              v-if="
                Number(Math.abs(quote.site_conditions_price_per_sqft)) > 0 ||
                Number(Math.abs(quote.options_price_per_sqft)) > 0
              "
              class="invoice-preview-card pagebreak"
              no-body
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <!-- Header: Left Content -->
                  <div />

                  <!-- Header: Right Content -->
                  <div class="mt-md-0 mt-2">
                    <h4 class="invoice-numbercs mb-2">
                      Quote #{{ quote.tfr.tfr }}
                    </h4>
                  </div>
                </div>

                <!-- Invoice Description: Table -->
                <div
                  v-if="
                    Number(Math.abs(quote.site_conditions_price_per_sqft)) > 0
                  "
                >
                  <h4
                    style="
                      padding-right: 28px;
                      padding-left: 28px;
                      font-weight: 500;
                    "
                  >
                    Site Conditions
                    <span
                      class="pl-1"
                      style="color:  rgba(59, 171, 255, 1) ; font-size: 14px; cursor: pointer"
                      @click="(tabIndex = 4), scrollToTop()"
                    >
                      <uil-edit-alt fill=" rgba(59, 171, 255, 1) " size="15px" /> Edit Section
                    </span>
                  </h4>

                  <br />
                  <b-table-simple
                    fixed
                    style="
                      padding-right: 28px;
                      padding-left: 28px;
                      overflow-y: hidden;
                    "
                  >
                    <b-thead>
                      <b-tr>
                        <b-th
                          class="table-background-color"
                          colspan="4"
                          style="text-align: left"
                        >
                          ITEM
                        </b-th>
                        <b-th
                          class="table-background-color"
                          colspan="2"
                          style="text-align: left"
                        >
                          PRICE/SQFT
                        </b-th>
                        <b-th
                          class="table-background-color cost-header"
                          colspan="2"
                          style="text-align: left"
                        >
                          COGS / SQFT
                        </b-th>
                        <b-th
                          class="table-background-color"
                          colspan="2"
                          style="text-align: left"
                        >
                          SUBTOTAL
                        </b-th>
                        <b-th
                          class="table-background-color cost-header"
                          colspan="2"
                          style="text-align: left"
                        >
                          PROFIT/SQFT
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        v-for="(siteCondition, index) in siteConditions"
                        :key="index"
                        class="border-group-details"
                      >
                        <template v-if="!siteCondition.is_base_spec">
                          <b-td class="row-label" colspan="4">
                            <div class="row-label">
                              {{ siteCondition.name }}
                            </div>
                            <div
                              v-if="siteCondition.details"
                              class="op-sc-details child-description"
                              style="white-space: pre-line"
                            >
                              {{ siteCondition.details }}
                            </div>
                          </b-td>
                          <b-td
                            :class="
                              [
                                siteCondition.price_per_sqft < 0
                                  ? 'credit '
                                  : '',
                              ] + 'row-label'
                            "
                            colspan="2"
                            >{{
                              [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((siteCondition.price_per_sqft)))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                            }}
                          </b-td>
                          <b-td
                            :class="
                              [
                                siteCondition.price_per_sqft < 0
                                  ? 'credit '
                                  : '',
                              ] + 'row-label cost-line'
                            "
                            colspan="2"
                            >{{
                              [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((siteCondition.cost_per_sqft)))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                            }}
                          </b-td>
                          <b-td
                            :class="[
                              siteCondition.price_per_sqft < 0 ? 'credit ' : '',
                            ]"
                            colspan="2"
                            >{{
                              [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(siteCondition.total_price))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                            }}
                          </b-td>
                          <b-td
                            :class="
                              [
                                siteCondition.price_per_sqft < 0
                                  ? 'credit '
                                  : '',
                              ] + 'row-label cost-line'
                            "
                            colspan="2"
                            >{{
                              [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((siteCondition.price_per_sqft - siteCondition.cost_per_sqft)))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                            }}
                          </b-td>
                        </template>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr class="border-footer">
                        <b-td
                          class="row-label"
                          colspan="4"
                          style="text-align: left"
                        >
                          Site Conditions Total
                        </b-td>
                        <b-td class="row-label" colspan="2"
                          >{{
                            [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_price_per_sqft))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                          }}
                        </b-td>
                        <b-td class="row-label cost-line" colspan="2">
                          {{
                            [(quote.site_conditions_cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_cost_per_sqft))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                          }}
                        </b-td>
                        <b-td class="row-label" colspan="2"
                          >{{
                            [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_total_price))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                          }}
                        </b-td>
                        <b-td class="cost-line" colspan="2"
                          >{{
                            [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_price_per_sqft - quote.site_conditions_cost_per_sqft))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                          }}
                        </b-td>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
                <div v-if="Number(Math.abs(quote.options_price_per_sqft)) > 0">
                  <h4
                    style="
                      padding-right: 28px;
                      padding-left: 28px;
                      padding-top: 50px;
                    "
                  >
                    Additional Scope & Upgrades
                    <span
                      class="pl-1"
                      style="color:  rgba(59, 171, 255, 1) ; font-size: 14px; cursor: pointer"
                      @click="(tabIndex = 5), scrollToTop()"
                    >
                      <uil-edit-alt fill=" rgba(59, 171, 255, 1) " size="15px" /> Edit Section
                    </span>
                  </h4>
                  <br />
                  <b-table-simple
                    fixed
                    style="
                      padding-right: 28px;
                      padding-left: 28px;
                      overflow-y: hidden;
                    "
                  >
                    <b-thead>
                      <b-tr>
                        <b-th
                          class="table-background-color"
                          colspan="4"
                          style="text-align: left"
                        >
                          ITEM
                        </b-th>
                        <b-th
                          class="table-background-color"
                          colspan="2"
                          style="text-align: left"
                        >
                          PRICE/SQFT
                        </b-th>
                        <b-th
                          class="table-background-color cost-header"
                          colspan="2"
                          style="text-align: left"
                        >
                          COGS / SQFT
                        </b-th>
                        <b-th
                          class="table-background-color"
                          colspan="2"
                          style="text-align: left"
                        >
                          SUBTOTAL
                        </b-th>
                        <b-th
                          class="table-background-color cost-header"
                          colspan="2"
                          style="text-align: left"
                        >
                          PROFIT/SQFT
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        v-for="(option, index) in options"
                        :key="index"
                        class="border-group-details"
                      >
                        <template v-if="!option.is_base_spec">
                          <b-td colspan="4">
                            <div class="row-label">
                              {{ option.name }}
                            </div>
                            <div
                              v-if="option.details"
                              class="op-sc-details child-description"
                              style="white-space: pre-line"
                            >
                              {{ option.details }}
                            </div>
                          </b-td>
                          <b-td
                            :class="
                              [option.price_per_sqft < 0 ? 'credit ' : ''] +
                              'row-label'
                            "
                            colspan="2"
                            >{{
                              [(option.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((option.price_per_sqft)))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                            }}
                          </b-td>
                          <b-td
                            :class="
                              [option.price_per_sqft < 0 ? 'credit ' : ''] +
                              'row-label cost-line'
                            "
                            colspan="2"
                            >{{
                              [(option.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((option.cost_per_sqft)))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                            }}
                          </b-td>
                          <b-td
                            :class="[
                              option.price_per_sqft < 0 ? 'credit ' : '',
                            ]"
                            colspan="2"
                            >{{
                              [(option.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(option.total_price))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                            }}
                          </b-td>
                          <b-td
                            :class="
                              [option.price_per_sqft < 0 ? 'credit ' : ''] +
                              'row-label cost-line'
                            "
                            colspan="2"
                          >
                            {{
                              option.price_per_sqft - option.cost_per_sqft < 0
                                ? "-"
                                : ""
                            }}
                            {{
                              [(option.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs((option.price_per_sqft - option.cost_per_sqft)))
                                  .toLocaleString('en', { minimumFractionDigits: 2 })
                            }}
                          </b-td>
                        </template>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr class="border-footer">
                        <b-td
                          class="row-label"
                          colspan="4"
                          style="text-align: left"
                        >
                          Additional Scope & Upgrades Total
                        </b-td>
                        <b-td class="row-label" colspan="2"
                          >{{
                            [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_price_per_sqft))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                          }}
                        </b-td>
                        <b-td class="row-label cost-line" colspan="2"
                          >{{
                            [(quote.options_cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_cost_per_sqft))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                          }}
                        </b-td>

                        <b-td colspan="2"
                          >{{
                            [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_total_price))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                          }}
                        </b-td>
                        <b-td class="row-label cost-line" colspan="2">
                          {{
                            quote.options_price_per_sqft -
                              quote.options_cost_per_sqft <
                            0
                              ? "-"
                              : ""
                          }}
                          {{
                            [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_price_per_sqft - quote.options_cost_per_sqft))
                                .toLocaleString('en', { minimumFractionDigits: 2 })
                          }}
                        </b-td>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
                <!-- Spacer -->
                <hr class="invoice-spacing" style="border: 2px solid #82868b" />
                <b-table-simple
                  fixed
                  style="
                    padding-right: 28px;
                    padding-left: 28px;
                    overflow-y: hidden;
                  "
                >
                  <b-tfoot>
                    <b-tr>
                      <b-td
                        class="row-label project-total-label"
                        colspan="4"
                        style="text-align: left; border-top: none"
                      >
                        Project Total
                      </b-td>
                      <b-td
                        class="row-label project-total-number"
                        colspan="2"
                        style="border-top: none"
                      >
                        ${{
                          Number(
                            quote.construction_cost_per_sqft +
                              quote.site_conditions_construction_price_per_sqft +
                              quote.options_construction_price_per_sqft +
                              quote.construction_buffer * 1 +
                              Number(
                                (!quote.ffe_toggle
                                  ? 0
                                  : quote.ffe_price_per_sqft) +
                                  quote.options_ffe_price_per_sqft +
                                  Number(
                                    (!quote.avit_toggle
                                      ? 0
                                      : quote.avit_price_per_sqft) +
                                      quote.options_avit_price_per_sqft
                                  ) +
                                  Number(
                                    quote.construction_pm_buffer * 1 +
                                      quote.design_ame_buffer * 1 +
                                      Number(quote.engineering_fee_price) * 1
                                  ) +
                                  Number(quote.permit_fees / quote.sqft)
                              ) +
                              Number(quote.site_conditions_price_per_sqft * 1) +
                              Number(quote.options_price_per_sqft * 1)
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        class="row-label project-total-number cost-line"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          "$" +
                          Number(
                            Number(
                              quote.construction_cost_per_sqft +
                                quote.options_construction_cost_per_sqft +
                                quote.site_conditions_construction_cost_per_sqft
                            ) +
                              Number(
                                (!quote.ffe_toggle
                                  ? 0
                                  : quote.ffe_cost_per_sqft +
                                    quote.ffe_installation_price_per_sqft) +
                                  quote.options_ffe_cost_per_sqft
                              ) +
                              Number(
                                (!quote.avit_toggle
                                  ? 0
                                  : quote.avit_cost_per_sqft +
                                    quote.avit_installation_price_per_sqft) +
                                  quote.options_avit_cost_per_sqft
                              ) +
                              Number(Number(quote.engineering_fee_cost) * 1) +
                              Number(quote.permit_fees / quote.sqft) +
                              Number(quote.site_conditions_cost_per_sqft) +
                              Number(quote.options_cost_per_sqft)
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        class="row-label project-total-number"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          "$" +
                          Number(
                            Number(
                              quote.construction_buffer * 1 * quote.sqft +
                                siteConditionsConstructionSubtotal +
                                optionsConstructionSubtotal +
                                quote.construction_calc
                            ) +
                              Number(
                                (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                                  optionsFfeSubtotal
                              ) +
                              Number(
                                (!quote.avit_toggle ? 0 : quote.avit_calc) +
                                  optionsAvItSubtotal
                              ) +
                              Number(
                                (quote.construction_pm_buffer * 1 +
                                  quote.design_ame_buffer * 1 +
                                  Number(quote.engineering_fee_price) * 1) *
                                  quote.sqft
                              ) +
                              Number(quote.permit_fees) +
                              Number(quote.options_total_price) +
                              Number(quote.site_conditions_total_price)
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                      <b-td
                        class="project-total-number cost-line"
                        colspan="2"
                        style="border-top: none"
                      >
                        {{
                          "$" +
                          Number(
                            quote.construction_buffer * 1 +
                              (Number(
                                quote.site_conditions_construction_price_per_sqft +
                                  quote.options_construction_price_per_sqft
                              ) -
                                Number(
                                  quote.options_construction_cost_per_sqft +
                                    quote.site_conditions_construction_cost_per_sqft
                                )) +
                              Number(!quote.ffe_toggle ? 0 : quote.ffe_buffer) +
                              Number(quote.options_ffe_price_per_sqft) -
                              Number(quote.options_ffe_cost_per_sqft) +
                              Number(
                                !quote.avit_toggle ? 0 : quote.avit_buffer
                              ) +
                              Number(quote.options_avit_price_per_sqft) -
                              Number(quote.options_avit_cost_per_sqft) +
                              Number(
                                quote.options_price_per_sqft -
                                  quote.options_cost_per_sqft
                              ) +
                              Number(
                                quote.site_conditions_price_per_sqft -
                                  quote.site_conditions_cost_per_sqft
                              ) +
                              quote.design_ame_buffer +
                              quote.construction_pm_buffer +
                              Number(
                                Number(Number(quote.engineering_fee_price)) *
                                  1 -
                                  Number(quote.engineering_fee_cost) * 1
                              )
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </b-card-body>

              <!-- Note -->
              <!-- Note -->
              <!-- <b-card-body class="invoice-padding pt-0">
                  <span class="disclaimer">This pricing does not contemplate liquidated damages. If liquidated damages are required, a premium will apply.<br>
                      Quote does NOT include <span v-if="quote.opportunity.geography == 'Toronto'">HST.</span><span v-else>include GST + QST.</span> <br>
                      Quote does NOT include any landlord required reviews and backcharges (incl. after hour fees, commissioning, security, engineer reviews, etc.).<br>
                      Changes to the scope of work listed above will be priced separately and agreed to in writing before additional work is performed. </span>
              </b-card-body> -->
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-constructor class="logo" size="15px" />
                Construction
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
                >
                <!-- ${{
                  Number(
                    quote.construction_cost_per_sqft +
                      quote.construction_buffer * 1 +
                      quote.site_conditions_construction_price_per_sqft +
                      quote.options_construction_price_per_sqft
                  ).toLocaleString("en", { minimumFractionDigits: 2 })
                }} -->
              </b-col>
            </template>
            <construction-breakdowns
              :quote="quote"
              :isBusy="isBusy"
              :constructionSpecs="constructionSpecs"
              :options="options"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-chair class="logo" size="15px" />
                FF&E
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
                >
                <!-- ${{
                  Number(
                    (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                      quote.options_ffe_price_per_sqft
                  ).toLocaleString("en", { minimumFractionDigits: 2 })
                }} -->
              </b-col>
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <b-card-title>
                  <b-row>
                    <b-col cols="10">
                      <h2>Furniture, Fixtures & Equipment</h2>
                    </b-col>
                    <b-col
                      align-self="end"
                      class="d-flex align-items-center justify-content-end"
                      cols="2"
                    >
                      <h4 style="color:  rgba(59, 171, 255, 1) ">
                        ${{
                          Number(
                            (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                              quote.options_ffe_price_per_sqft
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </h4>
                      <h4>/sqft</h4>
                    </b-col>
                  </b-row>
                </b-card-title>

                <b-row align-h="end">
                  <b-col
                    cols="3"
                    class="my-1"
                    align-self="end"
                    style="text-align: end"
                  >
                    <div
                      class="d-flex align-items-center justify-content-end"
                      align-self="end"
                      style="text-align: end"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-append
                          is-text
                          class="reset-append-border"
                        >
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-append>
                        <b-form-input
                          id="ffefilter-input"
                          v-model="ffeFilter"
                          type="search"
                          class="d-inline-block"
                          placeholder="Search"
                        />
                      </b-input-group>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <!-- <b-table
                        :busy="isBusy"
                        :fields="FFEbreakdownFields"
                        :items="ffeSpecs"
                        responsive="sm"
                        id="ffeBreakdownTable"
                        :per-page="ffePerPage"
                        :current-page="ffeCurrentPage"
                        :filter="ffeFilter"
                        @filtered="onFilteredFFE"
                        show-empty
                        fixed
                    > -->
                    <b-table
                      :busy="isBusy"
                      :fields="FFEbreakdownFields"
                      :items="ffeSpecs"
                      responsive="sm"
                      id="ffeBreakdownTable"
                      :filter="ffeFilter"
                      @filtered="onFilteredFFE"
                      show-empty
                      fixed
                    >
                      <template #cell(unit_price)="data">
                        ${{
                          Number(data.item.unit_price).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template #cell(supply_price)="data">
                        ${{
                          Number(data.item.supply_price).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template #cell(unit_delivery_install)="data">
                        ${{
                          Number(
                            data.item.unit_delivery_install
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template #cell(qty)="data">
                        {{ Number(data.item.qty).toLocaleString("en") }}
                      </template>
                      <template #cell(total_price)="data">
                        ${{
                          Number(data.item.total_price).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle" />
                          <strong> Loading...</strong>
                        </div>
                      </template>
                      <template #emptyfiltered>
                        <b-col cols="12" class="text-center">
                          <b-col cols="12" m-10>
                            <feather-icon
                              icon="SearchIcon"
                              size="4x"
                              style="color: #b9b9c3"
                            />
                          </b-col>
                          <b-col cols="12" class="mt-2">
                            <h4 style="color: #b9b9c3">No Results Found</h4>
                          </b-col>
                          <b-col
                            cols="12"
                            class="mt-1"
                            style="color:  rgba(59, 171, 255, 1) ; cursor: pointer"
                            @click="ffeFilter = null"
                          >
                            Clear search
                          </b-col>
                        </b-col>
                      </template>
                    </b-table>
                    <!-- <b-row style="float:right;" v-if="!isBusy">
                      <b-col cols="2">
                        <b-pagination
                            v-model="ffeCurrentPage"
                            :per-page="ffePerPage"
                            :total-rows="ffeRows"
                            aria-controls="ffeBreakdownTable"
                        />
                      </b-col>
                    </b-row> -->
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-wifi class="logo" size="15px" />
                AV / IT
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
                >
                <!-- {{
                  "$" +
                  Number(
                    (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                      quote.options_avit_price_per_sqft
                  ).toLocaleString("en", { minimumFractionDigits: 2 })
                }} -->
              </b-col>
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <b-card-title>
                  <b-row>
                    <b-col cols="10">
                      <h2>AV / IT</h2>
                    </b-col>
                    <b-col
                      align-self="end"
                      class="d-flex align-items-center justify-content-end"
                      cols="2"
                    >
                      <h4 style="color:  rgba(59, 171, 255, 1) ">
                        {{
                          "$" +
                          Number(
                            (!quote.avit_toggle
                              ? 0
                              : quote.avit_price_per_sqft) +
                              quote.options_avit_price_per_sqft
                          ).toLocaleString("en", { minimumFractionDigits: 2 })
                        }}
                      </h4>
                      <h4>/sqft</h4>
                    </b-col>
                  </b-row>
                </b-card-title>

                <b-row align-h="end">
                  <b-col
                    cols="3"
                    class="my-1"
                    align-self="end"
                    style="text-align: end"
                  >
                    <div
                      class="d-flex align-items-center justify-content-end"
                      align-self="end"
                      style="text-align: end"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-append
                          is-text
                          class="reset-append-border"
                        >
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-append>
                        <b-form-input
                          id="avitfilter-input"
                          v-model="avitFilter"
                          type="search"
                          class="d-inline-block"
                          placeholder="Search"
                        />
                      </b-input-group>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <b-table
                      :busy="isBusy"
                      :fields="AVITbreakdownFields"
                      :items="avitSpecs"
                      responsive="sm"
                      id="avitBreakdownTable"
                      :per-page="avitPerPage"
                      :current-page="avitCurrentPage"
                      :filter="avitFilter"
                      @filtered="onFilteredAVIT"
                      show-empty
                      fixed
                    >
                      <template #cell(unit_price)="data">
                        ${{
                          Number(data.item.unit_price).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template #cell(unit_delivery_install)="data">
                        ${{
                          Number(
                            data.item.unit_delivery_install
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template #cell(supply_price)="data">
                        ${{
                          Number(data.item.supply_price).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template #cell(qty)="data">
                        {{ Number(data.item.qty).toLocaleString("en") }}
                      </template>
                      <template #cell(total_price)="data">
                        ${{
                          Number(data.item.total_price).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle" />
                          <strong> Loading...</strong>
                        </div>
                      </template>
                      <template #emptyfiltered>
                        <b-col cols="12" class="text-center">
                          <b-col cols="12" m-10>
                            <feather-icon
                              icon="SearchIcon"
                              size="4x"
                              style="color: #b9b9c3"
                            />
                          </b-col>
                          <b-col cols="12" class="mt-2">
                            <h4 style="color: #b9b9c3">No Results Found</h4>
                          </b-col>
                          <b-col
                            cols="12"
                            class="mt-1"
                            style="color:  rgba(59, 171, 255, 1) ; cursor: pointer"
                            @click="avitFilter = null"
                          >
                            Clear search
                          </b-col>
                        </b-col>
                      </template>
                    </b-table>
                    <b-row style="float: right" v-if="!isBusy">
                      <b-col cols="2">
                        <b-pagination
                          v-model="avitCurrentPage"
                          :per-page="avitPerPage"
                          :total-rows="avitRows"
                          aria-controls="avitBreakdownTable"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-tape class="logo" size="15px" />
                Site Conditions
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
                >
                <!-- {{
                  [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_price_per_sqft))
                      .toLocaleString('en', { minimumFractionDigits: 2 })
                }} -->
              </b-col>
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <b-card-title>
                  <b-row>
                    <b-col cols="10">
                      <h2>Site Conditions</h2>
                    </b-col>
                    <b-col
                      align-self="end"
                      class="d-flex align-items-center justify-content-end"
                      cols="2"
                    >
                      <h4 style="color:  rgba(59, 171, 255, 1) ">
                        {{
                          [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.site_conditions_price_per_sqft))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </h4>
                      <h4>/sqft</h4>
                    </b-col>
                  </b-row>
                </b-card-title>
                <b-row>
                  <b-col
                    align-self="end"
                    cols="12"
                    style="text-align: end; padding-bottom: 20px"
                  >
                    <b-button
                      v-b-modal.site-conditions-modal
                      variant="primary"
                      :disabled="
                        quote.tfr.tfr_status == 'Quote Completed' ||
                        quote.opportunity.ccdc_signed == true ||
                        quote.tfr.tfr_status == 'Archived'
                      "
                    >
                      Add Items
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <!-- start draggable table -->
                    <b-table-simple
                      responsive
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 77.5vh"
                      :busy="isBusy"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fields"
                          :key="index"
                          v-if="fO.key != 'actions'"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>
                      <draggable
                        v-model="siteConditions"
                        group="items"
                        tag="tbody"
                        @change="onChange('site')"
                      >
                        <b-tr
                          v-for="item in siteConditions"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px; min-width: 200px">
                              {{ item.name }} <br />
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span
                              >
                            </div></b-td
                          >
                          <b-td>
                            <div>
                              {{ item.internal_note }}
                            </div>
                          </b-td>
                          <b-td>
                            <div v-if="item.uniformat!=null">{{ searchUniformatCodeName("Construction",item.uniformat) }}</div>
                          </b-td>
                          <b-td>
                            <div
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                            [(item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.price_per_sqft))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td>
                            <div
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                            >
                              {{
                            [(item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_price))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td>
                            <div class="cost-line" style="text-align: center">
                              {{
                                "$" +
                                Number(item.cost_per_sqft).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )
                              }}
                            </div>
                          </b-td>
                          <b-td>
                            <div class="cost-line" style="text-align: center">
                              {{
                                "$" +
                                Number(item.total_cost).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )
                              }}
                            </div>
                          </b-td>
                          <b-td>
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div v-else style="text-align: center">
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              />
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a
                                v-b-modal.edit-site-conditions-modal
                                @click="editSiteCondition(item)"
                              >
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                  >Edit</span
                                >
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteSiteCondition(item.id)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end draggable table -->
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col align-self="start" class="d-flex" cols="11">
                <uil-plus class="logo" size="15px" />
                <div style="width: 80%">Additional Scope & Upgrades</div>
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
                >
                <!-- {{
                  [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_price_per_sqft))
                      .toLocaleString('en', { minimumFractionDigits: 2 })
                }} -->
              </b-col>
            </template>
            <template v-if="!isBusy">
              <div
                v-if="showFFEAlert == true || showAVITAlert == true"
                class="alert-warning"
              >
                <span>
                  <UilExclamationTriangle fill="#FF9F43" size="15px" />
                  Additional Scope & Upgrades are still being included in a
                  disabled budget group.</span
                >
              </div>
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <b-card-title>
                  <b-row>
                    <b-col cols="10">
                      <h2>Additional Scope & Upgrades</h2>
                    </b-col>
                    <b-col
                      align-self="end"
                      class="d-flex align-items-center justify-content-end"
                      cols="2"
                    >
                      <h4 style="color:  rgba(59, 171, 255, 1) ">
                        {{
                          [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(quote.options_price_per_sqft))
                              .toLocaleString('en', { minimumFractionDigits: 2 })
                        }}
                      </h4>
                      <h4>/sqft</h4>
                    </b-col>
                  </b-row>
                </b-card-title>
                <b-row>
                  <b-col
                    align-self="end"
                    cols="12"
                    style="text-align: end; padding-bottom: 20px"
                  >
                    <b-button
                      v-b-modal.options-modal
                      variant="primary"
                      :disabled="
                        quote.tfr.tfr_status == 'Quote Completed' ||
                        quote.opportunity.ccdc_signed == true ||
                        quote.tfr.tfr_status == 'Archived'
                      "
                    >
                      Add Items
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <!-- start As&U drag and drop table -->
                    <b-table-simple
                      class="table-item"
                      responsive
                      :busy="isBusy"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      style="height: 77.5vh"
                    >
                      <b-thead>
                        <b-th
                          v-for="(fO, index) in fieldsOptions"
                          :key="index"
                          v-if="fO.key != 'actions'"
                          class="sticky"
                        >
                          <div style="white-space: nowrap">
                            {{ fO.label }}
                          </div>
                        </b-th>
                        <b-th
                          :class="visibleActions()"
                          class="sticky text-center"
                        >
                          <span :class="visibleActions()">ACTIONS</span>
                        </b-th>
                      </b-thead>

                      <!-- Draggable rows -->
                      <draggable
                        v-model="options"
                        group="items"
                        tag="tbody"
                        @change="onChange('option')"
                      >
                        <b-tr
                          v-for="item in options"
                          :key="item.id"
                          class="item-row"
                        >
                          <b-td>
                            <div style="max-width: 400px">
                              {{ item.name }} <br />
                              <span
                                v-if="item.details"
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 18px;
                                  letter-spacing: 0px;
                                  text-align: left;
                                  color: #b9b9c3;
                                  white-space: pre-line;
                                "
                              >
                                {{ item.details }}</span
                              >
                            </div></b-td
                          >
                          <b-td>{{ item.internal_note }}</b-td>
                          <b-td>
                            <div v-if="item.uniformat!=null">{{ searchUniformatCodeName(item.budget_group=='AV/IT'?'AVIT':item.budget_group,item.uniformat) }}
                            </div>
                          </b-td>
                          <b-td>
                            <b-badge
                              pill
                              variant="primary"
                              :class="
                                item.budget_group.replace(/[^\w\s]/gi, '')
                              "
                              style="
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 13px;
                              "
                            >
                              <div v-if="item.budget_group == 'Construction'">
                                <uil-constructor /> CSTRN
                              </div>
                              <div v-else-if="item.budget_group == 'FF&E'">
                                <uil-chair /> FF&E
                              </div>
                              <div v-else><uil-wifi /> AVIT</div>
                            </b-badge></b-td
                          >
                          <b-td>
                            <div
                              v-if="item.is_base_spec"
                              style="text-align: center"
                            >
                              <feather-icon
                                class="align-middle text-body"
                                icon="CheckIcon"
                                size="16"
                              />
                            </div>
                            <div v-else style="text-align: center">
                              <feather-icon
                                class="align-middle text-body"
                                icon="XIcon"
                                size="16"
                              /></div
                          ></b-td>
                          <b-td>
                            <div
                              :class="[item.price_per_sqft < 0 ? 'credit' : '']"
                              style="text-align: center"
                              v-if="item.budget_group != 'Construction'"
                            >
                              {{
                            [(item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.price_per_sqft))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div></b-td
                          >
                          <b-td>
                            <div
                              :class="[item.total_price < 0 ? 'credit' : '']"
                              style="text-align: center"
                              v-if="item.budget_group != 'Construction'"
                            >
                              {{
                            [(item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_price))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td>
                            <div class="cost-line" style="text-align: center">
                              <uil-arrow-growth
                                style="color: #ff9f43"
                                v-if="item.is_adjustment == true"
                              />
                              {{
                            [(item.cost_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.cost_per_sqft))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td>
                            <div class="cost-line" style="text-align: center">
                              <uil-arrow-growth
                                style="color: #ff9f43"
                                v-if="item.is_adjustment == true"
                              />
                              {{
                            [(item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(item.total_cost))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                              }}
                            </div>
                          </b-td>
                          <b-td :class="visibleActions()">
                            <div class="text-nowrap">
                              <a
                                v-b-modal.edit-options-modal
                                @click="editOption(item)"
                              >
                                <feather-icon
                                  id="tooltip-eye"
                                  class="mx-1"
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin-right: 8px !important"
                                />
                                <span
                                  class="align-middle"
                                  style="margin-right: 15px"
                                  >Edit</span
                                >
                              </a>
                              <b-tooltip
                                target="tooltip-eye"
                                title="Edit Option"
                              />

                              <!-- Dropdown -->
                              <b-dropdown
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                              >
                                <template #button-content>
                                  <feather-icon
                                    class="align-middle text-body"
                                    icon="MoreVerticalIcon"
                                    size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                  target="_blank"
                                  @click="deleteOption(item.id)"
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-td>
                        </b-tr>
                      </draggable>
                    </b-table-simple>
                    <!-- end AS&U drag and drop table -->
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-setting class="logo" size="15px" />
                Settings
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <template v-if="!isBusy">
              <div
                v-if="showFFEAlert == true || showAVITAlert == true"
                class="alert-warning"
              >
                <span>
                  <UilExclamationTriangle fill="#FF9F43" size="15px" />
                  Additional Scope & Upgrades are still being included in a
                  disabled budget group.</span
                >
              </div>
            </template>
            <settings-assumptions-exclusions
              :quote="quote"
              @optionsChanged="settingsChanged"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-history class="logo" size="15px" />
                Quote Activity Log
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <activity-log :quote="quote" />
          </b-tab>
          <template #tabs-end>
            <comment-section
              ref="commentComponent"
              :comments="comments"
              :demolition-image="quote.demolition_image"
              :quote-id="quoteId"
              style="max-width: none"
              @commentChanged="commentChanged"
            />
          </template>
        </b-tabs>
      </b-col>
    </b-row>

    <options-create-modal
      :quote-id="quoteId"
      :sqft="quote.sqft"
      @updatedOptions="childOptions"
      :constructionItems="quoteConstructionItems"
      :base_spec_version="base_spec_version_c"
    />
    <options-edit-modal
      :option-to-edit="optionToEdit"
      :quote-id="quoteId"
      :sqft="quote.sqft"
      @updatedOptions="childOptions"
      :constructionItems="quoteConstructionItems"
      :base_spec_version="base_spec_version_c"
    />
    <site-conditions-create-modal
      :quote-id="quoteId"
      :sqft="quote.sqft"
      @updatedSiteConditions="childSiteConditions"
    />
    <site-conditions-edit-modal
      :quote-id="quoteId"
      :site-condition-to-edit="siteConditionToEdit"
      :sqft="quote.sqft"
      @updatedSiteConditions="childSiteConditions"
    />
  </section>
</template>

<script>
import {
  UilBill,
  UilBox,
  UilCheckCircle,
  UilEditAlt,
  UilExclamationTriangle,
  UilHardHat,
  UilPlus,
  UilRuler,
  UilSuitcase,
  UilTape,
  UilWifi,
  UilSetting,
  UilConstructor,
  UilArrowGrowth,
  UilHistory,
  UilTrashAlt,
} from "@iconscout/vue-unicons";
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTableSimple,
  BTabs,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTooltip,
  BTr,
  VBToggle,
  BBadge,
} from "bootstrap-vue";
import UilChair from "@/assets/CustomIcons/uil-chair";
import JsonExcel from "vue-json-excel";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CommentSection from "../Comments/Components/Comment.vue";
import OptionsCreateModal from "../Options/Components/CreateModal.vue";
import OptionsEditModal from "../Options/Components/EditModal.vue";
import SiteConditionsCreateModal from "../SiteConditions/Components/CreateModal.vue";
import SiteConditionsEditModal from "../SiteConditions/Components/EditModal.vue";
import SettingsAssumptionsExclusions from "./Components/SettingsAssumptionsExclusionsTab.vue";
import ConstructionBreakdowns from "./Components/ConstructionBreakdownsTab.vue";
import ActivityLog from "./Components/ActivityLogTab.vue";
import QuoteInfoHeader from "./Components/QuoteInfoHeader.vue";
import draggable from "vuedraggable";
import uniformat from "@/assets/objects/uniformat-codes.json";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BFormCheckbox,
    BTableSimple,
    BButton,
    BTable,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
    CommentSection,
    UilRuler,
    UilSuitcase,
    UilBill,
    UilTape,
    UilBox,
    UilWifi,
    UilHardHat,
    UilPlus,
    UilCheckCircle,
    UilEditAlt,
    UilExclamationTriangle,
    BFormDatepicker,
    BInputGroupAppend,
    BFormInput,
    BInputGroup,
    BTabs,
    BTab,
    OptionsCreateModal,
    OptionsEditModal,
    SiteConditionsCreateModal,
    SiteConditionsEditModal,
    BPagination,
    BCardTitle,
    JsonExcel,
    UilSetting,
    SettingsAssumptionsExclusions,
    ConstructionBreakdowns,
    BBadge,
    UilChair,
    UilConstructor,
    UilArrowGrowth,
    UilHistory,
    ActivityLog,
    UilTrashAlt,
    UilChair,
    QuoteInfoHeader,
    draggable,
  },
  data() {
    return {
      quoteId: this.$route.params.quoteid,
      quote: {
        opportunity: {},
        comments: {},
        construction_cost_per_sqft: 0,
        construction_buffer: 0,
      },
      tabIndex: 0,
      isBusy: true,
      comments: {},
      show: true,
      newComment: "",
      showFFEAlert: false,
      showAVITAlert: false,
      showConstructionAlert: false,
      fields: [
        {
          key: "name",
          label: "ITEM",
          sortable: true,
        },
        {
          key: "internal_note",
          label: "internal note",
          sortable: false,
        },
        {
          key: "uniformat_code",
          label: "tender code",
          sortable: false,
        },
        {
          key: "price_per_sqft",
          label: "Price / SQFT",
          sortable: false,
        },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
          thStyle: { width: "15%" },
          tdClass: "center_header",
        },
        {
          key: "cost_per_sqft",
          label: "COGS / SQFT",
          sortable: true,
        },
        {
          key: "total_cost",
          label: "Total COGS",
          sortable: true,
        },
        {
          key: "is_base_spec",
          label: "HIDDEN",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTIONS",
          tdClass: this.visibleActions + "text-center",
        },
      ],
      fieldsOptions: [
        {
          key: "name",
          label: "ITEM",
          sortable: true,
        },
        {
          key: "internal_note",
          label: "INTERNAL NOTE",
          sortable: false,
        },
        {
          key: "uniformat_code",
          label: "tender code",
          sortable: false,
        },
        {
          key: "budget_group",
          label: "BUDGET",
          sortable: false,
        },
        {
          key: "is_base_spec",
          label: "HIDDEN",
          sortable: true,
        },
        {
          key: "price_per_sqft",
          label: "Price / SQFT",
          sortable: false,
        },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
        },
        {
          key: "cost_per_sqft",
          label: "COGS / SQFT",
          sortable: true,
        },
        {
          key: "total_cost",
          label: "Total COGS",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTIONS",
          tdClass: this.visibleActions,
        },
      ],
      breakdownFields: [
        {
          key: "name",
          label: "ITEM",
          sortable: true,
        },
        {
          key: "qty",
          label: "QTY",
          sortable: false,
        },
        {
          key: "total_cost",
          label: "Cost",
          sortable: true,
        },
        {
          key: "cost_per_sqft",
          label: "$/sqft",
          sortable: true,
        },
      ],
      FFEbreakdownFields: [
        {
          key: "name",
          label: "ELEMENT",
          sortable: false,
          thClass: "text-left",
        },
        {
          key: "supply_price",
          label: "Price / Unit",
          sortable: false,
          thClass: "text-left",
        },
        {
          key: "unit_delivery_install",
          label: "Delivery and install / unit",
          sortable: false,
          thClass: "text-left",
        },
        {
          key: "qty",
          label: "quantity",
          sortable: true,
          thClass: "text-left",
        },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
          thClass: "text-left",
        },
      ],
      AVITbreakdownFields: [
        {
          key: "name",
          label: "ELEMENT",
          sortable: false,
          thClass: "text-left",
        },
        {
          key: "supply_price",
          label: "Price / Unit",
          sortable: false,
          thClass: "text-left",
        },
        {
          key: "unit_delivery_install",
          label: "Delivery and install / unit",
          sortable: false,
          thClass: "text-left",
        },
        {
          key: "qty",
          label: "quantity",
          sortable: true,
          thClass: "text-left",
        },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
          thClass: "text-left",
        },
      ],
      options: [],
      siteConditions: [],
      constructionSpecs: [],
      optionToEdit: [],
      optionsPerPage: 15,
      optionsCurrentPage: 1,
      siteConditionToEdit: [],
      siteConditionsPerPage: 15,
      siteConditionsCurrentPage: 1,
      ffeSpecs: [],
      ffePerPage: 15,
      ffeCurrentPage: 1,
      ffeFilter: null,
      avitSpecs: [],
      avitPerPage: 15,
      avitCurrentPage: 1,
      avitFilter: null,
      stickyHeader: true,
      noCollapse: true,
      quoteConstructionItems: [],
      originalConstructionBuffer: 0,
      originalDesignBuffer: 0,
      originalConstructionPmBuffer: 0,
      originalLanguage: "",
      originalAvItToggle: true,
      originalFfeToggle: true,
      assumptionsExclusionsChanged: false,
      buttonDiscard: false,
      originalunion_labour_free: false,
      originalraised_access_floor_clear: false,
      originalffe_clear: false,
      originalmeets_building_code: false,
      originalbuilding_equipment_good_condition: false,
      originalbuilt_demising_interior_partitions: false,
      originalhazardous_material_clear: false,
      originaldemolition: false,
      originalaccess_included: false,
      originalbas_systems_included: false,
      originalelectrical_panel_transformer_included: false,
      originaladitional_demising_walls_included: false,
      originalperimeter_bulkhead_included: false,
      originalblinds_included: false,
      originalsuite_entry_doors_included: false,
      originalwashroom_work_included: false,
      originalelevator_lobby_work_included: false,
      originalabatement_hazardous_material_included: false,
      originallandlord_required_reviews_included: false,
      childComment: "",
      discardMessage: "",
      // * uniformat codes object
      uniformat_codes:uniformat
    };
  },
  computed: {
    optionRows() {
      return this.options.length;
    },
    siteConditionRows() {
      return this.siteConditions.length;
    },
    ffeRows() {
      return this.ffeSpecs.length;
    },
    avitRows() {
      return this.avitSpecs.length;
    },
    checkHiddenFFE() {
      return this.quote.options_ffe_price_per_sqft != 0;
    },
    checkHiddenAVIT() {
      return this.quote.options_avit_price_per_sqft != 0;
    },
    checkHiddenConstruction() {
      return (
        this.quote.site_conditions_construction_price_per_sqft != 0 ||
        this.quote.options_construction_price_per_sqft != 0
      );
    },
    optionsAvItSubtotal() {
      var sum = 0;
      this.options
        .filter((n) => n.budget_group == "AV/IT" && n.is_base_spec == true)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    optionsFfeSubtotal() {
      var sum = 0;
      this.options
        .filter((n) => n.budget_group == "FF&E" && n.is_base_spec == true)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    optionsConstructionSubtotal() {
      var sum = 0;
      this.options
        .filter(
          (n) => n.budget_group == "Construction" && n.is_base_spec == true
        )
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    siteConditionsConstructionSubtotal() {
      var sum = 0;
      this.siteConditions
        .filter(
          (n) => n.budget_group == "Construction" && n.is_base_spec == true
        )
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    hasComment() {
      if (this.childComment != "") {
        return true;
      } else {
        return false;
      }
    },
    quoteHasChanged() {
      return (
        this.originalConstructionBuffer != this.quote.construction_buffer ||
        this.originalDesignBuffer != this.quote.design_ame_buffer ||
        this.originalConstructionPmBuffer !=
          this.quote.construction_pm_buffer ||
        this.originalLanguage != this.quote.language ||
        this.originalAvItToggle != this.quote.avit_toggle ||
        this.originalFfeToggle != this.quote.ffe_toggle ||
        this.originalunion_labour_free !=
          this.quote.assumptions_exclusions.union_labour_free ||
        this.originalraised_access_floor_clear !=
          this.quote.assumptions_exclusions.raised_access_floor_clear ||
        this.originalffe_clear != this.quote.assumptions_exclusions.ffe_clear ||
        this.originalmeets_building_code !=
          this.quote.assumptions_exclusions.meets_building_code ||
        this.originalbuilding_equipment_good_condition !=
          this.quote.assumptions_exclusions.building_equipment_good_condition ||
        this.originalbuilt_demising_interior_partitions !=
          this.quote.assumptions_exclusions
            .built_demising_interior_partitions ||
        this.originalhazardous_material_clear !=
          this.quote.assumptions_exclusions.hazardous_material_clear ||
        this.originaldemolition !=
          this.quote.assumptions_exclusions.demolition ||
        this.originalaccess_included !=
          this.quote.assumptions_exclusions.access_included ||
        this.originalbas_systems_included !=
          this.quote.assumptions_exclusions.bas_systems_included ||
        this.originalelectrical_panel_transformer_included !=
          this.quote.assumptions_exclusions
            .electrical_panel_transformer_included ||
        this.originaladitional_demising_walls_included !=
          this.quote.assumptions_exclusions.aditional_demising_walls_included ||
        this.originalperimeter_bulkhead_included !=
          this.quote.assumptions_exclusions.perimeter_bulkhead_included ||
        this.originalblinds_included !=
          this.quote.assumptions_exclusions.blinds_included ||
        this.originalsuite_entry_doors_included !=
          this.quote.assumptions_exclusions.suite_entry_doors_included ||
        this.originalwashroom_work_included !=
          this.quote.assumptions_exclusions.washroom_work_included ||
        this.originalelevator_lobby_work_included !=
          this.quote.assumptions_exclusions.elevator_lobby_work_included ||
        this.originalabatement_hazardous_material_included !=
          this.quote.assumptions_exclusions
            .abatement_hazardous_material_included ||
        this.originallandlord_required_reviews_included !=
          this.quote.assumptions_exclusions.landlord_required_reviews_included
      );
    },
    discardModalMessage() {
      if (this.hasComment && this.quoteHasChanged) {
        return "It looks like you have unsaved changes and comments on this page.";
      } else if (this.hasComment) {
        return "It looks like you have unsaved comments on this page.";
      } else {
        return "It looks like you have unsaved changes on this page.";
      }
    },
    montrealAddress() {
      if (this.quote.language == "fr") {
        return "1325-1010 rue de la Gauchetière Ouest";
      } else {
        return "1325-1010 de la Gauchetière West Street";
      }
    },
    construction_pm_buffer: {
      get() {
        return parseFloat(this.quote.construction_pm_buffer).toFixed(2);
      },
      set(newValue) {
        this.quote.construction_pm_buffer = newValue;
      },
    },
    design_ame_buffer: {
      get() {
        return parseFloat(this.quote.design_ame_buffer).toFixed(2);
      },
      set(newValue) {
        this.quote.design_ame_buffer = newValue;
      },
    },
    construction_buffer: {
      get() {
        return parseFloat(this.quote.construction_buffer).toFixed(2);
      },
      set(newValue) {
        this.quote.construction_buffer = newValue;
      },
    },
    base_spec_version_c() {
      return this.quote.base_spec_version;
    },
  },
  beforeMount() {
    this.getQuotes();
  },
  methods: {
    downloadCadFile() {
      this.$http({
        url: `/quote/${this.quoteId}/cad-data`,
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.quote.csv_filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    getQuotes() {
      this.$http
        .get(`/quote/${this.quoteId}`)
        .then((response) => {
          this.quote = response.data;
          this.comments = this.quote.comments;
          this.options = this.quote.options;
          this.siteConditions = this.quote.site_conditions;
          this.constructionSpecs = this.quote.construction_spec_quote;
          this.ffeSpecs = this.quote.ffe_spec_quote;
          this.avitSpecs = this.quote.avit_spec_quote;
          this.show = false;
          this.isBusy = false;
          this.updateBreadcrumb();
          this.hasIncludedInTurnKey();
          this.quoteConstructionItems =
            this.quote.construction_spec_quote.filter(
              (x) => x.name !== "Construction Management"
            );
          this.originalConstructionBuffer = this.quote.construction_buffer;
          this.originalDesignBuffer = this.quote.design_ame_buffer;
          this.originalConstructionPmBuffer = this.quote.construction_pm_buffer;
          this.originalLanguage = this.quote.language;
          this.originalAvItToggle = this.quote.avit_toggle;
          this.originalFfeToggle = this.quote.ffe_toggle;
          this.originalunion_labour_free =
            this.quote.assumptions_exclusions.union_labour_free;
          this.originalraised_access_floor_clear =
            this.quote.assumptions_exclusions.raised_access_floor_clear;
          this.originalffe_clear = this.quote.assumptions_exclusions.ffe_clear;
          this.originalmeets_building_code =
            this.quote.assumptions_exclusions.meets_building_code;
          this.originalbuilding_equipment_good_condition =
            this.quote.assumptions_exclusions.building_equipment_good_condition;
          this.originalbuilt_demising_interior_partitions =
            this.quote.assumptions_exclusions.built_demising_interior_partitions;
          this.originalhazardous_material_clear =
            this.quote.assumptions_exclusions.hazardous_material_clear;
          this.originaldemolition =
            this.quote.assumptions_exclusions.demolition;
          this.originalaccess_included =
            this.quote.assumptions_exclusions.access_included;
          this.originalbas_systems_included =
            this.quote.assumptions_exclusions.bas_systems_included;
          this.originalelectrical_panel_transformer_included =
            this.quote.assumptions_exclusions.electrical_panel_transformer_included;
          this.originaladitional_demising_walls_included =
            this.quote.assumptions_exclusions.aditional_demising_walls_included;
          this.originalperimeter_bulkhead_included =
            this.quote.assumptions_exclusions.perimeter_bulkhead_included;
          this.originalblinds_included =
            this.quote.assumptions_exclusions.blinds_included;
          this.originalsuite_entry_doors_included =
            this.quote.assumptions_exclusions.suite_entry_doors_included;
          this.originalwashroom_work_included =
            this.quote.assumptions_exclusions.washroom_work_included;
          this.originalelevator_lobby_work_included =
            this.quote.assumptions_exclusions.elevator_lobby_work_included;
          this.originalabatement_hazardous_material_included =
            this.quote.assumptions_exclusions.abatement_hazardous_material_included;
          this.originallandlord_required_reviews_included =
            this.quote.assumptions_exclusions.landlord_required_reviews_included;
          if (this.quote.tfr.tfr) {
            document.title = `[${this.quote.tfr.tfr}] Edit Quote`;
          }
        })
        .catch((error) => {
          // this.showToast('danger', error.response.data.message ?? '')
        });
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = `${this.quote.opportunity.address}, ${this.quote.opportunity.geography}`;
      this.$route.meta.breadcrumb[0].to = `/opportunities/${this.$route.params.opportunityid}`;
      this.$route.meta.breadcrumb[0].active = false;
      this.$route.meta.breadcrumb[2].text = `Quote #${this.quote.tfr.tfr}`;
      this.$route.meta.breadcrumb[2].to = `/opportunities/${this.$route.params.opportunityid}/${this.$route.params.quoteid}`;
      this.$route.meta.breadcrumb[2].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    toggleChanged(toggleCategory) {
      if (toggleCategory == "ffe") {
        if (this.quote.ffe_toggle) {
          this.showToast(
            "primary",
            "Added FF&E budget to spaces.",
            "Added FF&E budget."
          );
        } else {
          this.showToast(
            "primary",
            "Removed FF&E budget from spaces.",
            "Removed FF&E budget."
          );
        }
      } else if (this.quote.avit_toggle) {
        this.showToast(
          "primary",
          "Added AV/IT budget to spaces.",
          "Added AV/IT budget."
        );
      } else {
        this.showToast(
          "primary",
          "Removed AV/IT budget from spaces.",
          "Removed AV/IT budget."
        );
      }
      this.hasIncludedInTurnKey();
    },
    updateQuote() {
      this.buttonDiscard = true;
      this.$http
        .put(`/quote/${this.quoteId}`, {
          construction_buffer: this.quote.construction_buffer * 1,
          avit_buffer: this.quote.avit_buffer,
          ffe_buffer: this.quote.ffe_buffer,
          design_ame_buffer: this.quote.design_ame_buffer,
          construction_pm_buffer: this.quote.construction_pm_buffer,
          issue_date: this.quote.issue_date,
          avit_toggle: this.quote.avit_toggle,
          // add toggles to avoid errors
          av_toggle: this.quote.av_toggle,
          it_toggle: this.quote.it_toggle,
          ffe_toggle: this.quote.ffe_toggle,
          assumptions_exclusions: this.quote.assumptions_exclusions,
          language: this.quote.language,
          options: this.options,
          siteConditions: this.siteConditions,
        })
        .then((response) => {
          this.showToast(
            "success",
            `Quote #${response.data.tfr_name} has been successfully saved.`,
            "Quote saved successfully"
          );
          this.$router.push({
            path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
          });
        })
        .catch((error) => {
          this.showToast("danger", "Error updating Quote", "Notification");
        });
    },
    printInvoice() {
      window.print();
    },
    initials(text) {
      const initial = text
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();

      return initial;
    },
    editOption(option) {
      this.optionToEdit = option;
      // this.$bvModal.show('edit-options-modal')
    },
    deleteOption(id) {
      this.$http
        .delete(`/quote/${this.quoteId}/options/${id}`)
        .then((response) => {
          this.showToast(
            "success",
            "Option Deleted Successfully",
            "Notification"
          );
          this.options = response.data.options;
          this.quote.options_price_per_sqft = response.data.price_per_sqft;
          this.quote.options_total_price = response.data.total_price;
          this.quote.options_construction_price_per_sqft =
            response.data.price_per_sqft_construction;
          this.quote.options_construction_cost_per_sqft =
            response.data.cost_per_sqft_construction;
          this.quote.options_avit_price_per_sqft =
            response.data.price_per_sqft_avit;
          this.quote.options_avit_cost_per_sqft =
            response.data.cost_per_sqft_avit;
          this.quote.options_ffe_price_per_sqft =
            response.data.price_per_sqft_ffe;
          this.quote.options_ffe_cost_per_sqft =
            response.data.cost_per_sqft_ffe;
        })
        .catch((error) => {
          this.showToast("danger", "Error Deleting Option", "Notification");
        });
    },
    deleteSiteConditions(id) {
      console.log(id);
    },

    childOptions(variable) {
      this.options = variable.options;
      this.quote.options_price_per_sqft = variable.price_per_sqft;
      this.quote.options_cost_per_sqft = variable.cost_per_sqft;
      this.quote.options_construction_price_per_sqft =
        variable.price_per_sqft_construction;
      this.quote.options_construction_cost_per_sqft =
        variable.cost_per_sqft_construction;
      this.quote.options_ffe_price_per_sqft = variable.price_per_sqft_ffe;
      this.quote.options_ffe_cost_per_sqft = variable.cost_per_sqft_ffe;
      this.quote.options_avit_price_per_sqft = variable.price_per_sqft_avit;
      this.quote.options_avit_cost_per_sqft = variable.cost_per_sqft_avit;
      this.quote.options_total_price = variable.total_price;
      this.hasIncludedInTurnKey();
      this.showToast("success", "Added to budget", "Added budget to spaces.");
    },
    settingsChanged(settings) {
      this.quote.avit_toggle = settings.avit_toggle;
      this.quote.ffe_toggle = settings.ffe_toggle;
      this.quote.assumptions_exclusions = settings.assumptions_exclusions;
      this.quote.language = settings.language;
      this.assumptionsExclusionsChanged = true;
      this.hasIncludedInTurnKey();
    },
    commentChanged(comment) {
      this.childComment = comment;
    },
    childSiteConditions(variable) {
      this.siteConditions = variable.site_conditions;
      this.quote.site_conditions_price_per_sqft = variable.price_per_sqft;
      this.quote.site_conditions_construction_price_per_sqft =
        variable.price_per_sqft_construction;
      this.quote.site_conditions_construction_cost_per_sqft =
        variable.cost_per_sqft_construction;
      this.quote.site_conditions_total_price = variable.total_price;
    },
    editSiteCondition(option) {
      this.siteConditionToEdit = option;
    },
    deleteSiteCondition(id) {
      this.$http
        .delete(`/quote/${this.quoteId}/site-conditions/${id}`)
        .then((response) => {
          this.showToast(
            "success",
            "Site Condition Deleted Successfully",
            "Notification"
          );
          this.siteConditions = response.data.site_conditions;
          this.quote.site_conditions_price_per_sqft =
            response.data.price_per_sqft;
          this.quote.site_conditions_total_price = response.data.total_price;
          this.quote.site_conditions_construction_price_per_sqft =
            response.data.price_per_sqft_construction;
          this.quote.site_conditions_construction_cost_per_sqft =
            response.data.cost_per_sqft_construction;
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "Error Deleting Site Condition",
            "Notification"
          );
        });
    },
    hasIncludedInTurnKey() {
      if (
        this.quote.ffe_toggle == false &&
        Object.values(this.options).some(
          (val) => val.budget_group == "FF&E" && val.is_base_spec === true
        )
      ) {
        this.showFFEAlert = true;
      } else {
        this.showFFEAlert = false;
      }

      if (
        this.quote.avit_toggle == false &&
        Object.values(this.options).some(
          (val) => val.budget_group == "AV/IT" && val.is_base_spec === true
        )
      ) {
        this.showAVITAlert = true;
      } else {
        this.showAVITAlert = false;
      }
    },

    onFilteredFFE(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.ffetotalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredAVIT(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.avittotalRows = filteredItems.length;
      this.currentPage = 1;
    },

    discardChanges() {
      console.log(this.$refs.commentComponent.newComment);
    },
    showModal() {
      this.buttonDiscard = true;
      this.$bvModal
        .msgBoxConfirm(this.discardModalMessage, {
          title: "You Have Unsaved Changes",
          size: "sm",
          okVariant: "outline-danger",
          okTitle: "Discard Changes",
          cancelTitle: "Return to Edit",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$router.push({
              path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
            });
          } else {
            this.buttonDiscard = false;
            return false;
          }
        });
    },
    exitEditMode() {
      this.$router.push({
        path: `/opportunities/${this.$route.params.opportunityid}/${this.quoteId}`,
      });
    },
    
    visibleActions() {
      return this.quote.tfr.tfr_status == "Quote Completed" ||
        this.quote.opportunity.ccdc_signed == true ||
        this.quote.tfr.tfr_status == "Archived"
        ? "d-none"
        : "";
    },

    badgeClass(status) {
      if (status == "Awaiting Test Fit") {
        return "awaiting-test-fit";
      } else if (status == "Reviewing Test Fit") {
        return "reviewing-test-fit";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Awaiting Quote") {
        return "awaiting-quote";
      } else if (status == "Quote in Progress") {
        return "quote-in-progress";
      } else if (status == "Submitted for Handover" || status == "Active") {
        return "submitted-for-handover";
      } else if (status == "Reviewing Quote") {
        return "reviewing-quote";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      } else if (status == "Hardened") {
        return "hardened";
      } else if (status == "Quote Completed") {
        return "quote-completed";
      }
    },
    onChange(event) {
      this.reorder(event);
    },
    reorder(toOrder) {
      if (toOrder == "option") {
        this.options.forEach((item, index) => (item.order = index + 1));
      } else {
        this.siteConditions.forEach((item, index) => (item.order = index + 1));
      }
    },
    searchUniformatCodeName(subgroup, code){
      let codeName=""
      this.uniformat_codes[subgroup].map((u)=>{
        if(u.code==code){
          codeName= u.code_name
        }
      })
      return codeName
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.buttonDiscard && (this.quoteHasChanged || this.hasComment)) {
      this.$bvModal
        .msgBoxConfirm(this.discardModalMessage, {
          title: "You Have Unsaved Changes",
          size: "sm",
          okVariant: "outline-danger",
          okTitle: "Discard Changes",
          cancelTitle: "Return to Edit",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  .breadcrumbs-top {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
  [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    background-color: rgba(92, 177, 231, 0.12) !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  th {
    text-align: left !important;
  }

  #lottie {
    display: none;
  }

  .caption-table {
    /* Heading/H4 */

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    padding-left: 40px;
    color: #5e5873;
  }

  .row-label {
    /* Body/Paragraph Semi Bold */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 150% */

    align-items: center;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .border-group {
    border-bottom: 2px solid #d8d6de;
  }

  .border-child {
    border-bottom: 2px solid white;
  }

  .child-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .border-footer {
    border-top: 2px solid  rgba(59, 171, 255, 1) ;
  }

  .invoice-numbercs {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
  }

  .header-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .header-label-grey {
    /* Table/Table Header */

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Typography/Muted & Placeholder */

    color: #b9b9c3;
  }

  .header-subtotal {
    font-style: normal;
    font-weight: 600;
    font-size: 11.9px;
    line-height: 12px;
    /* identical to box height, or 100% */

    text-align: right;
    letter-spacing: 0.14px;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .project-sqft {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* Typography/Heading & Display */

    color: #5e5873;
  }

  .project-sqft-total {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: end;

    /* Theme Colour / Primary */

    color:  rgba(59, 171, 255, 1) ;
  }

  .width-100p {
    width: 100%;
  }
}

th {
  text-align: left !important;
}

.comment-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid  rgba(59, 171, 255, 1) ;
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color:  rgba(59, 171, 255, 1) ;
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}

.cost-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}

.cost-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #b9b9c3;
}

.child-description {
  font-size: 12px;
  color: #b9b9c3;
  line-height: 18px;
}

.turnkey-subtotal-primary {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #6e6b7b;
}

.turnkey-subtotal-secondary {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #b9b9c3;
}

.credit {
  color:  rgba(59, 171, 255, 1) ;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.labelpersqft {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #b9b9c3;
}

th {
  text-align: center;
}

.alert-warning {
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 159, 67, 0.12),
      rgba(255, 159, 67, 0.12)
    ),
    #ffffff;
  border-radius: 4px;
  margin-bottom: 2rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.alert-warning > span {
  color: #ff9f43;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}

.archived-warning {
  width: 100%;
  background: #d4f4f7;
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.archived-warning > span {
  color: #03d8ed;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}

.hidden-flex-line > td {
  font-size: 12px;
}

.remove-border > td {
  border: none !important;
}
.border-section > td {
  border-bottom: 1px solid #ebe9f1;
}

.input-group > .reset-append-border > .input-group-text {
  border-left: 1px solid #d8d6de !important;
  border-top-left-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}
.input-group:focus-within > .reset-append-border > .input-group-text {
  border-left: 1px solid  rgba(59, 171, 255, 1)  !important;
  border-top-left-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}

// @media screen and (min-width: 360px) and (max-width: 1300px) {
//     .edit-page [role=tablist] {
//       width: min-content;
//     }
// }
// @media screen and (max-width: 1440px){
//     .edit-page [role=tablist] {
//       width: 260px;
//     }
// }
// @media screen and (min-width: 1441px){
//     .edit-page [role=tablist] {
//       width: 350px;
//     }
// }

.FFE {
  background-color: rgba(32, 201, 151, 0.12);
  color: #20c997;
}

.Construction {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43;
}

.AVIT {
  background-color: rgba(13, 110, 253, 0.12);
  color: #7367f0;
}

.b-table-sticky-header {
  max-height: unset;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: rgba(50, 61, 76, 1) !important;
}
.center_header {
  text-align: center !important;
}
.sticky {
  position: sticky;
  top: 0;
  color: rgba(50, 61, 76, 1) !important;
  z-index: 10;
}
.archived-warning-archived {
  width: 100%;
  background: rgba(30, 30, 30, 0.12);
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.archived-warning-archived > span {
  color: #4b4b4b;
  font-weight: 400;
  font-size: 14px;
  padding-left: 15px;
}
</style>
